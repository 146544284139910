import { Chip } from '@mui/material'

const getScoreColorClass = (score) => {
  if (score < 0.2) {
    return 'score_e'
  }
  if (score < 0.4) {
    return 'score_d'
  }
  if (score < 0.6) {
    return 'score_c'
  }
  if (score < 0.8) {
    return 'score_b'
  }

  if (score <= 1) {
    return 'score_a'
  }

  return 'default'
}

export const ScoreBadge = ({ score, title, verbose }) => {
  // Make sure we don't ignore 0
  if (score === null || score === undefined) {
    return <Chip size="small" color="default" label={<b>NA</b>}></Chip>
  }

  const percent = score > 1 ? 1 : score < 0 ? 0 : score

  if (verbose) {
    return (
      <Chip
        size="small"
        title={title}
        color={getScoreColorClass(percent)}
        label={
          <b>
            {percent === 1 ? percent * 100 : (percent * 100).toFixed(0)} {title}
          </b>
        }
      ></Chip>
    )
  }

  return (
    <Chip
      size="small"
      title={title}
      color={getScoreColorClass(percent)}
      label={
        <b>{percent === 1 ? percent * 100 : (percent * 100).toFixed(1)}</b>
      }
    ></Chip>
  )
}
