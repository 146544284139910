import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { Fragment, useEffect, useState } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { AuthContext } from './contexts/AuthContext'
import Header from './components/Header'
import Search from './components/Search'
import Home from './Home'
import { ApiClient } from './network/ApiClient'
import Account from './pages/Account'
import Privacy from './pages/Privacy'
import ScreenerPage from './pages/ScreenerPage'
import SignUpPage from './pages/SignUpPage'
import { Terms } from './pages/Terms'
import { getCurrentUser, logout, onAuthChanged } from './services/auth'
import { removeCustomerId } from './services/cache'
import { AppContext } from './contexts/AppContext'
import { getStockData } from './services/stocks'
import { Payment } from './pages/Payment'
import LoginPage from './pages/LoginPage'
import Dashboard from './pages/Dashboard'
import { setCookie } from './utils/Cookies'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { Footer } from './components/Footer'
import * as tracking from './services/tracking'

export default function App() {
  const [forbidden, setForbidden] = useState(true)
  const [signedIn, setSignedIn] = useState(false)
  const [stockData, setStockData] = useState(undefined)
  const [priceData, setPriceData] = useState(undefined)
  const [competitors, setCompetitors] = useState(undefined)
  const [query, setQuery] = useState('')
  const [queryResults, setQueryResults] = useState([])
  const [loading, setLoading] = useState(true)
  const [symbol, setSymbol] = useState(undefined)
  const [location, setLocation] = useState(undefined)
  const [error, setError] = useState(false)
  const [userId, setUserId] = useState(undefined)
  const [bookmarks, setBookmarks] = useState(undefined)
  const [industriesAverage, setIndustriesAverageResponse] = useState(undefined)
  const [account, setAccount] = useState(undefined)
  const [valuations, setValuations] = useState(undefined)
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const windowLocation = useLocation()
  const lgMediaQuery = useMediaQuery('(min-width:1024px)')
  const navigate = useNavigate()
  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            // borderRadius: '8px',
            // borderStyle: 'solid',
            // borderWidth: '1px',
            // borderColor: '#e2e2e2',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#184641',
      },
      secondary: {
        main: '#fff',
      },
      score_a: {
        main: '#D1E7DD',
        contrastText: '#051B11',
      },
      score_b: {
        main: '#CFE2FF',
        contrastText: '#031633',
      },
      score_c: {
        main: '#FFF3CD',
        contrastText: '#332701',
      },
      score_d: {
        main: '#FFE5D0',
        contrastText: '#331904',
      },
      score_e: {
        main: '#F8D7DA',
        contrastText: '#2C0B0E',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  })

  useEffect(() => {
    const inter = setInterval(() => {
      if (getCurrentUser()) {
        getCurrentUser()
          .getIdTokenResult(true)
          .then((idTokenResult) => {
            setCookie('auth-token', idTokenResult.token)
            setLastUpdate(new Date())
          })
      }
    }, 5000 * 60)

    onAuthChanged(
      ({ authToken, user, claims }) => {
        setLoading(true)
        const uid = user.multiFactor.user.uid
        setUserId(uid)
        setCookie('auth-token', authToken)
        setLastUpdate(new Date())

        const apiClient = new ApiClient()
        apiClient
          .getAccountDetails()
          .then((account) => {
            setAccount(account)
            setSignedIn(true)
            setLoading(false)
            tracking.login('auth', uid)
          })
          .catch(() => {
            setLoading(false)
          })
      },
      () => {
        removeCustomerId()
        setForbidden(true)
        setSignedIn(false)
        setCookie('auth-token', undefined)
        logout().then(() => navigate('/signin'))
      }
    )

    return () => clearInterval(inter)
  }, [])

  const fetchStockData = async (ticker, location) => {
    setLoading(true)
    setQueryResults([])
    setQuery('')
    setError(false)
    setIndustriesAverageResponse({ loading: true })

    try {
      const data = await getStockData(ticker, location)
      setStockData(data)
      setSymbol(ticker)
      setLocation(location)
      const apiClient = new ApiClient()

      apiClient
        .getQuote(ticker, location)
        .then((data) => setPriceData(data))
        .catch((err) => setPriceData(undefined))
      apiClient
        .getCompetitors(ticker, location)
        .then((data) => setCompetitors(data.competitors))
        .catch((err) => setCompetitors(undefined))
      apiClient
        .getIndustriesAverage()
        .then(({ avgs }) => setIndustriesAverageResponse(avgs))
        .catch((err) => setIndustriesAverageResponse(undefined))
      apiClient
        .getValuations(ticker, location)
        .then((res) => setValuations(res.valuations))
        .catch((err) => setValuations(undefined))
    } catch (err) {
      setError(true)
      console.error('Could not get stock data.', err)
    } finally {
      setLoading(false)
    }
  }

  var indexNav = undefined

  if (
    windowLocation.pathname === '/' ||
    windowLocation.pathname === '/premium'
  ) {
    indexNav = 1
  }

  if (windowLocation.pathname === '/bookmarks') {
    indexNav = 0
  }

  if (windowLocation.pathname === '/account') {
    indexNav = 2
  }

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider
          value={{
            account: account,
            setAccount: setAccount,
            userId: userId,
            forbidden: forbidden,
            setForbidden: setForbidden,
            signedIn: signedIn,
            setSignedIn: setSignedIn,
            bookmarks: bookmarks,
            setBookmarks: setBookmarks,
          }}
        >
          <PayPalScriptProvider
            options={{
              'disable-funding': 'credit',
              components: 'buttons',
              currency: 'USD',
              vault: true,
              intent: 'subscription',
              'client-id':
                process.env.NODE_ENV === 'production'
                  ? process.env.REACT_APP_PROD_PAYPAL_CLIENT_ID
                  : process.env.REACT_APP_DEV_PAYPAL_CLIENT_ID,
            }}
          >
            <AppContext.Provider
              value={{
                lastUpdate,
                symbol: symbol,
                location: location,
                loading: loading,
                priceData: priceData,
                stockData: stockData,
                competitors: competitors,
                query: query,
                queryResults: queryResults,
                setStockData: setStockData,
                onLoading: setLoading,
                onPriceChanged: setPriceData,
                onStockChanged: setStockData,
                onCompetitorsChanged: setCompetitors,
                fetchStockData: fetchStockData,
                setQuery: setQuery,
                setQueryResults: setQueryResults,
                setSymbol: setSymbol,
                setLocation: setLocation,
                valuations: valuations,
                failedToFetch: error,
                industriesAverages: industriesAverage,
                getAveragesByIndustry: (industry) =>
                  industriesAverage.loading
                    ? null
                    : industriesAverage.find((i) => i._id === industry),
              }}
            >
              <Header lg={lgMediaQuery}>
                <Search />
              </Header>

              <Routes>
                <Route path="premium/:index" element={<ScreenerPage />} />
                <Route
                  path="premium"
                  element={<Navigate to="/premium/SP500" />}
                />
                <Route path="/" element={<Navigate to="/premium" />} />
                <Route path="analysis/:symbol/:location" element={<Home />} />
                <Route path="bookmarks" element={<Dashboard />} />
                <Route path="payment" element={<Payment />} />
                <Route path="account" element={<Account />} />
                <Route path="signup" element={<SignUpPage />} />
                <Route path="signin" element={<LoginPage />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="*" element={<Navigate to="404.html" />}></Route>
              </Routes>
              <Outlet />
              <Footer />
              {/* {signedIn && !lgMediaQuery && (
                <BottomNavigation
                  showLabels
                  style={{ position: 'sticky', bottom: '0' }}
                  value={indexNav}
                >
                  <BottomNavigationAction
                    label="Bookmarks"
                    icon={<span class="material-icons">bookmark</span>}
                    onClick={() => navigate('/bookmarks')}
                  />
                  <BottomNavigationAction
                    label="Screener"
                    icon={<span class="material-icons">view_list</span>}
                    onClick={() => navigate('/')}
                  />
                  <BottomNavigationAction
                    label="Account"
                    icon={<span class="material-icons">person</span>}
                    onClick={() => navigate('/account')}
                  />
                </BottomNavigation>
              )} */}
            </AppContext.Provider>
          </PayPalScriptProvider>
        </AuthContext.Provider>
      </ThemeProvider>
    </Fragment>
  )
}
