import { SocialIcon } from 'react-social-icons'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <footer>
      <div className="app-wrapper">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <span>{`© ${currentYear} ValueStocks.io`}</span>
            <br />
            {/* <span>Proudly Made in 🇨🇦</span> */}
          </div>

          {/* <div>
            <ul>
              <li>
                <a href="https://valuestocks.io">Home Page</a>
              </li>
              <li>
                <a href="https://valuestocks.io/blog">Blog</a>
              </li>
              <li></li>
            </ul>
          </div>

          <div>
            <ul>
              <li>
                <a href="/terms">Terms of Service</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="mailto:contact@valuestocks.io">Contact</a>
              </li>
              <li>
                <SocialIcon
                  url="https://www.facebook.com/valuestocks.io"
                  fgColor="white"
                  label="facebook"
                  style={{
                    width: '2rem',
                    height: '2rem',
                    marginRight: '0.5rem',
                    marginTop: '0.5rem',
                  }}
                />
                <SocialIcon
                  url="https://twitter.com/ValueStocks_IO"
                  fgColor="white"
                  label="twitter"
                  style={{
                    width: '2rem',
                    height: '2rem',
                    marginRight: '0.5rem',
                    marginTop: '0.5rem',
                  }}
                />
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      <p className="disclaimer">
        Disclaimer: ValueStocks.io is not operated by a broker, a dealer, or a
        registered investment adviser. Under no circumstances does any
        information posted on ValueStocks.io represent a recommendation to buy
        or sell a security. The information on this site, and in its related
        blog, email and newsletters, is not intended to be, nor does it
        constitute, investment advice or recommendations. In no event shall
        ValueStocks.io be liable for any damages of any kind arising out of the
        use of any product, content or other material published or available on
        ValueStocks.io, or relating to the use of, or inability to use,
        ValueStocks.io or any content, including, without limitation, any
        investment losses, lost profits, lost opportunity, special, incidental,
        indirect, consequential or punitive damages. The information on this
        site is in no way guaranteed for completeness, accuracy or in any other
        way.
      </p>
    </footer>
  )
}
