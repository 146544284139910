import { Fragment } from 'react'
import Explain from './Explain'

export default function GrowthTitle() {
  return (
    <div style={{ display: 'inline-flex' }}>
      Growth Score&nbsp;
      <Explain
        title="Sustainable Growth Score"
        description={
          <Fragment>
            <span>
              The Sustainable Growth Score is a powerful feature that assesses
              the overall financial strength and stability of a company. It
              takes into consideration multiple key parameters to provide a
              comprehensive evaluation of a company's financial health.
            </span>
            <br />
            <br />
            <span>Parameters considered in the score:</span>
            <br />
            <br />
            <span>
              1. Cashflow greater than long-term debt: This parameter evaluates
              the company's ability to generate enough cashflow to cover its
              long-term debt obligations, indicating a healthy cashflow position
              and lower risk of default.
            </span>
            <br />
            <br />
            <span>
              2. Long-term debt greater than revenue: This parameter assesses
              the company's debt-to-revenue ratio, indicating its level of
              indebtedness relative to its revenue. A lower ratio suggests a
              healthier debt position and lower risk of financial distress.
            </span>
            <br />
            <br />
            <span>
              3. Gross margin higher than 10%: This parameter measures the
              company's profitability, with a focus on its gross margin. A gross
              margin higher than 10% indicates a favorable profit margin and
              better profitability.
            </span>
            <br />
            <br />
            <span>
              4. Earnings per share growth, Dividend per share growth, Operating
              income growth, and Revenue growth: These parameters evaluate the
              company's historical growth in earnings, dividends, operating
              income, and revenue, respectively. Positive growth in these areas
              suggests a company's ability to generate increasing profits and
              revenues over time.
            </span>
            <br />
            <br />
            <span>
              5. Maturity: This parameter assesses the company's stage of
              maturity, taking into consideration factors such as industry,
              market position, and business model. A more mature company may be
              considered more stable and less risky compared to younger or less
              established companies.
            </span>
            <br />
            <br />
            <span>
              The Comprehensive Financial Health Score is calculated by
              combining and weighting these parameters using a predetermined
              formula. The higher the score, the stronger the company's overall
              financial health and stability. This score provides users with a
              comprehensive assessment of a company's financial well-being,
              helping them make informed decisions when evaluating investment
              opportunities or assessing credit risk.
            </span>
          </Fragment>
        }
      />
    </div>
  )
}
