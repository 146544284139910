import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'

export default function TemporaryDrawer({ isOpen, handleToggleDrawer }) {
  const navigate = useNavigate()
  const authContext = useAuthContext()
  const isPremium =
    authContext.account &&
    authContext.account.plan === 'premium' &&
    !authContext.account.expired
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    handleToggleDrawer(open)
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(decodeURI(`/account`))}>
            <ListItemIcon>
              <span className="material-icons">account_circle</span>
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(decodeURI(`/bookmarks`))}>
            <ListItemIcon>
              <span className="material-icons">bookmarks</span>
            </ListItemIcon>
            <ListItemText primary="Bookmarks" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(decodeURI(`/premium`))}>
            <ListItemIcon>
              <span className="material-icons">view_list</span>
            </ListItemIcon>
            <ListItemText primary="Screener" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {[
          { text: 'S&P 500', slug: 'SP500' },
          { text: 'S&P TSX', slug: 'SPTSX' },
          { text: 'S&P ASX', slug: 'SPASX' },
          { text: 'FTSE 100', slug: 'FTSE100' },
          { text: 'Russell 2000', slug: 'RUSSELL2000' },
          { text: 'China ADRs', slug: 'CHINA_ADR' },
        ].map(({ text, slug }, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              disabled={!isPremium && slug !== 'SP500'}
              onClick={() => navigate(decodeURI(`/premium/${slug}`))}
            >
              <ListItemIcon>
                <span className="material-icons">
                  {!isPremium && slug !== 'SP500' ? 'lock' : 'arrow_right'}
                </span>
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <div>
      <Fragment key="left">
        <Drawer
          anchor={'left'}
          open={isOpen}
          onClose={toggleDrawer('left', false)}
        >
          {list('left')}
        </Drawer>
      </Fragment>
    </div>
  )
}
