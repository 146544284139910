import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth'

const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()

const firebaseConfig = {
  apiKey: 'AIzaSyDG4IJXser86HnuG8HNqVXLq1riHx2j73U',
  authDomain: 'app.valuestocks.io',
  projectId: 'vlustx',
  storageBucket: 'vlustx.appspot.com',
  messagingSenderId: '855584646727',
  appId: '1:855584646727:web:c366e9c8bd60eb2a3c3362',
  measurementId: 'G-MGWWDVCW71',
}

const app = firebase.initializeApp(firebaseConfig)
const auth = app.auth()
if (process.env.NODE_ENV !== 'production') {
  auth.useEmulator('http://localhost:9099')
}

export const signIn = async ({ email, password }) => {
  // clearCache(STOCKS_DATA_KEY) // refresh cache when passing from 5 years history to 20 years
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  const userCredentials = await auth.signInWithEmailAndPassword(email, password)
  return userCredentials.user.uid
}

export const registerWithEmailAndPassword = async ({ email, password }) => {
  // clearCache(STOCKS_DATA_KEY) // refresh cache when passing from 5 years history to 20 years
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  const userCredentials = await auth.createUserWithEmailAndPassword(
    email,
    password
  )

  return userCredentials.user.uid
}

export const logout = async () => {
  return auth.signOut()
}

export const signInWithGoogle = async () => {
  const userCredentials = await auth.signInWithPopup(googleProvider)
  return userCredentials.user.uid
}

export const signInWithFacebook = async () => {
  const userCredentials = await auth.signInWithPopup(facebookProvider)
  return userCredentials.user.uid
}

export const forgotEmail = async (email) => {
  return auth.sendPasswordResetEmail(email)
}

export const getCurrentUser = () => auth.currentUser

export const onAuthChanged = (successCb, errorCb) =>
  auth.onAuthStateChanged(function (user) {
    console.log('Auth Changed')
    if (user) {
      user.getIdTokenResult().then((idTokenResult) =>
        successCb({
          authToken: idTokenResult.token,
          claims: idTokenResult.claims,
          user,
        })
      )
    } else {
      errorCb()
    }
  })
