import { Fragment } from 'react'
import Explain from './Explain'

export default function FinancesTitle() {
  return (
    <div style={{ display: 'inline-flex' }}>
      Finances Score&nbsp;
      <Explain
        title="Finances Score"
        description={
          <Fragment>
            <span>
              The Finance Score is calculated by using the Piotroski F-score.
              The Piotroski F-score is a nine-point scoring system that
              evaluates a company's financial strength based on several
              financial indicators such as profitability, leverage, liquidity,
              and efficiency. The F-score ranges from 0 to 9, with a higher
              score indicating a stronger financial position. A perfect F-score
              of 9 represents a company that meets all nine criteria, indicating
              excellent financial health.
            </span>
            <br />
            <br />
            <span>
              The nine criteria are profitability, cash flow from operations,
              change in return on assets (ROA), change in asset turnover, change
              in gross margin, change in financial leverage, change in current
              ratio, change in shares outstanding, and change in the gross
              margin rank. Each criterion is assigned a score of 0 or 1,
              depending on whether it meets specific financial thresholds.
            </span>
            <br />
            <br />
            <span>
              Once the app evaluates each criterion, it can calculate the
              Finance Score by summing the scores of all nine criteria. For
              example, a company that meets all nine criteria would receive a
              perfect score of 9, which would represent a Finance Score of
              100/100. Similarly, a company that meets only five of the nine
              criteria would receive a score of 5, representing a Finance Score
              of 55/100.
            </span>
            <br />
            <br />
            <span>
              The Finance Score feature can provide investors with valuable
              insights into a company's financial health, helping them make
              informed investment decisions. Investors can use the Finance Score
              to compare companies within the same industry or sector and
              identify companies with a strong financial position.
            </span>
          </Fragment>
        }
      />
    </div>
  )
}
