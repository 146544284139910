import { Fragment } from 'react'
import Explain from './Explain'

export const TotalScoreTitle = () => {
  return (
    <Fragment>
      Total Score&nbsp;
      <Explain
        title="Total Score"
        description={
          <Fragment>
            <span>
              The Total Score is an overall ranking of a stock based on several
              factors, including the margin of safety, sustained growth,
              financial health, and creditworthiness of the company. These
              factors are each assigned a score on a scale of 0 to 100 and
              weighted according to their relative importance.
            </span>
            <br />
            <br />
            <span>
              The Total Score is then calculated as a weighted average of these
              scores and expressed as a value between 0 and 100. A higher Total
              Score indicates a stronger overall ranking.
            </span>
          </Fragment>
        }
      />
    </Fragment>
  )
}
