import './App.css'
import React, { Fragment, useEffect, useState } from 'react'
import { Line, Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Legend,
  Filler,
  LogarithmicScale,
} from 'chart.js'
import { BigNumber } from './components/BigNumber'
import { Loading } from './components/Loading'
import { get, last, mean } from 'lodash'
import Bookmarks from './components/Bookmarks'
import { takeRight } from 'lodash'
import Toggle from './components/Toggle'
import { Oops } from './components/Oops'
import {
  blue,
  green,
  red,
  teal,
  orange,
  majenta,
  getTransparentColor,
  green5,
  yellow,
  green6,
} from './utils/Colors'
import { inBillions, inMillions } from './utils/Numbers'
import zoomPlugin from 'chartjs-plugin-zoom'
import { StockItem } from './components/StockItem'
import { BackToTop } from './components/BackToTop'
import { useAppContext } from './contexts/AppContext'
import { useNavigate, useParams } from 'react-router-dom'
import AutoScrollToTop from './components/AutoScrollToTop'
import useLocalStorage from './useStorage'
import { VOTE_KEY } from './services/cache'
import SubscriptionGuard from './components/SubscriptionGuard'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
  Chip,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
  IconButton,
  AppBar,
  Toolbar,
  Button,
  useScrollTrigger,
  Slide,
} from '@mui/material'
import StockItemHeader from './components/StockItemHeader'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ScoreDetailSectionComponent } from './components/ScoreDetailsComponent'
import { TotalScore } from './components/TotalScore'
import * as tracking from './services/tracking'
import ErrorBoundary from './components/ErrorBoundary'
import { grey } from '@mui/material/colors'

ChartJS.register(
  LogarithmicScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  zoomPlugin
)

const logarithmicScale = {
  scales: {
    y: {
      type: 'logarithmic',
    },
  },
}

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: true,
    responsive: true,
    legend: {
      position: 'top',
    },
  },
  elements: {
    point: {
      radius: 1,
    },
  },
}

const lineChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      enabled: true,
      intersect: false,
      mode: 'index',
      padding: 12,
      displayColors: true,
    },
    legend: {
      position: 'top',
    },
  },
}

const chartLinePlugin = [
  {
    afterDraw: (chart) => {
      if (chart.tooltip?._active?.length) {
        let x = chart.tooltip._active[0].element.x
        let yAxis = chart.scales.y
        let ctx = chart.ctx
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, yAxis.top)
        ctx.lineTo(x, yAxis.bottom)
        ctx.lineWidth = 2
        ctx.strokeStyle = green6
        ctx.stroke()
        ctx.restore()
      }
    },
  },
]

const roundFloat = (v) => v.toFixed(2)

function Home() {
  const [showOnlyLast5years, setShowOnlyLast5Years] = useState(false)
  const [period, setPeriod] = useState('annual')
  const [vote, setVote] = useLocalStorage(VOTE_KEY, {})
  const [voteResults, setVoteResults] = useState(undefined)
  const lgMediaQuery = useMediaQuery('(min-width:1024px)')
  const [showMore, showLess] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [scoreDetailsExpanded, setScoreDetailsExpanded] = useState(false)
  const navigate = useNavigate()

  const routeParams = useParams()
  const appContext = useAppContext()
  const symbol = routeParams.symbol
  const location = routeParams.location

  const getCurrency = (location) => {
    switch (location) {
      case 'US':
        return 'USD'
      case 'CA':
        return 'CAD'
      case 'LN':
        return 'GBP'
      case 'AU':
        return 'AUD'
      case 'NZ':
        return 'NZD'
      case 'MM':
        return 'MXN'
      default:
        return 'USD'
    }
  }
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: getCurrency(location),
    symbol: true,
  })

  useEffect(() => {
    window.scrollTo({ top: 0 })
    appContext.fetchStockData(symbol, location)
    tracking.analyze(`${symbol}:${location}`)
  }, [symbol, location])

  const getMetricForPeriod = (metric) => {
    if (period === 'annual') {
      if (showOnlyLast5years) {
        return takeRight(
          get(appContext.stockData.financials[period], metric, []),
          5
        )
      }
      return get(appContext.stockData.financials[period], metric, [])
    } else if (period === 'quarterly') {
      if (showOnlyLast5years) {
        return takeRight(
          get(appContext.stockData.financials[period], metric, []),
          5 * 4
        )
      }
      return get(appContext.stockData.financials[period], metric)
    }
  }

  const renderCompetitors = () => {
    if (lgMediaQuery) {
      if (appContext.competitors) {
        return (
          <Card variant="outline">
            <CardHeader title="Industry Competitors" id="industry" />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <StockItemHeader lg={lgMediaQuery} />
                  </TableHead>
                  <TableBody>
                    {appContext.competitors.map((c) => {
                      return <StockItem stock={c} lg={lgMediaQuery} />
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )
      }
    } else {
      if (appContext.competitors) {
        return (
          <Card id="industry" variant="outline">
            <CardHeader title="Industry Competitors" />
            <CardContent style={{ padding: '0.5rem' }}>
              {appContext.competitors.map((c) => {
                return <StockItem stock={c} lg={lgMediaQuery} />
              })}
            </CardContent>
          </Card>
        )
      }
    }
  }

  const barChartOptions = (color) => ({
    borderColor: color,
    backgroundColor: getTransparentColor(color),
    hoverBackgroundColor: color,
    borderWidth: 1.3,
    borderRadius: 0,
    tension: 0.3,
  })

  const handleTabChange = (e, value) => {
    setSelectedTab(value)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  const renderValuationsDetails = () => {
    if (!appContext.valuations || !appContext.valuations.vs_model) {
      return
    }

    const data = {
      labels: [
        'Average',
        'Graham Formula',
        'Discounted Cash Flow',
        'ValueStocks Model',
        'Dididend Discount Model',
        'Gordon Growth Model',
      ],
      datasets: [
        {
          label: 'Valuations',
          datalabels: {
            formatter: (value) => currencyFormatter.format(value),
            display: true,
            textAlign: 'center',
            anchor: 'start',
            align: 'top',
            // offset: 5,
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
          data: [
            appContext.valuations.fair_price,
            appContext.valuations.benjamin_graham.result,
            appContext.valuations.dcf.result,
            appContext.valuations.vs_model.price,
            appContext.valuations.ddm.result,
            appContext.valuations.gordon.result,
          ],
          borderWidth: 1.3,
          backgroundColor: (context) => {
            var index = context.dataIndex

            if (index === 0) {
              return getTransparentColor(green)
            }

            if (
              appContext.valuations.valuation_method === 'DCF' &&
              index <= 3
            ) {
              return getTransparentColor(green)
            }

            if (
              appContext.valuations.valuation_method === 'DCF' &&
              index >= 4
            ) {
              return getTransparentColor(grey[300])
            }

            if (
              appContext.valuations.valuation_method !== 'DCF' &&
              index <= 3
            ) {
              return getTransparentColor(grey[300])
            }

            return getTransparentColor(green)
          },
          borderColor: (context) => {
            var index = context.dataIndex

            if (index === 0) {
              return green
            }

            if (
              appContext.valuations.valuation_method === 'DCF' &&
              index <= 3
            ) {
              return green
            }

            if (
              appContext.valuations.valuation_method === 'DCF' &&
              index >= 4
            ) {
              return grey[300]
            }

            if (
              appContext.valuations.valuation_method !== 'DCF' &&
              index <= 3
            ) {
              return grey[300]
            }

            return green
          },
          hoverBackgroundColor: (context) => {
            var index = context.dataIndex

            if (index === 0) {
              return green
            }

            if (
              appContext.valuations.valuation_method === 'DCF' &&
              index <= 3
            ) {
              return green
            }

            if (
              appContext.valuations.valuation_method === 'DCF' &&
              index >= 4
            ) {
              return grey[300]
            }

            if (
              appContext.valuations.valuation_method !== 'DCF' &&
              index <= 3
            ) {
              return grey[300]
            }

            return green
          },
        },
      ],
    }

    return (
      <Fragment>
        <div id="payout-ratio">
          <Bar
            options={{
              ...chartOptions,
              plugins: {
                legend: { display: false },
              },
              scales: {
                x: {
                  display: false,
                },
                y: {
                  display: false,
                },
              },
            }}
            plugins={[ChartDataLabels]}
            data={data}
          />
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <Tab label="Average" wrapped />
            <Tab label="Graham Formula" wrapped />
            <Tab label="Discounted Cash Flow" wrapped />
            <Tab label="ValueStocks Model" wrapped />
            <Tab label="Dividend Discount Model" wrapped />
            <Tab label="Gordon Growth Model" wrapped />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          {renderAverageDetails(appContext.valuations.fair_price)}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {renderGrahamDetails(appContext.valuations.benjamin_graham)}
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          {renderDCFDetails(appContext.valuations.dcf)}
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          {renderVsModelDetails(appContext.valuations.vs_model)}
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
          {renderDDMDetails(appContext.valuations)}
        </TabPanel>
        <TabPanel value={selectedTab} index={5}>
          {renderGordonGrowthDetails(appContext.valuations.gordon)}
        </TabPanel>
      </Fragment>
    )
  }

  const renderAverageDetails = (fairPrice) => {
    return (
      <Fragment>
        {renderValuationDetails(fairPrice)}
        {renderCollapsibleDetails(
          <div>
            {appContext.valuations.valuation_method === 'DCF' ? (
              <div style={{ textAlign: 'left' }}>
                The average is given by three valuation models:
                <br />
                <ul>
                  <li>
                    Discounted Cash Flow{' '}
                    <Chip
                      size="small"
                      label={appContext.valuations.dcf.result.toFixed(2)}
                    ></Chip>
                  </li>
                  <li>
                    Benjamin Graham{' '}
                    <Chip
                      size="small"
                      label={appContext.valuations.benjamin_graham.result.toFixed(
                        2
                      )}
                    ></Chip>
                  </li>
                  <li>
                    ValueStocks Model{' '}
                    <Chip
                      size="small"
                      label={appContext.valuations.vs_model.price.toFixed(2)}
                    ></Chip>
                  </li>
                </ul>
              </div>
            ) : (
              <div style={{ textAlign: 'left' }}>
                The average is given by two valuation models:
                <br />
                <ul>
                  <li>
                    Dividend Discount Model{' '}
                    <Chip
                      size="small"
                      label={appContext.valuations.ddm.result.toFixed(2)}
                    ></Chip>
                  </li>
                  <li>
                    Gordon Growth Model{' '}
                    <Chip
                      size="small"
                      label={appContext.valuations.gordon.result.toFixed(2)}
                    ></Chip>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </Fragment>
    )
  }

  const renderValuationDetails = (fairPrice) => {
    if (!appContext.priceData) {
      return
    }

    const options = {
      maintainAspectRatio: false,
      indexAxis: 'y',
      responsive: true,
      plugins: {
        datalabels: {
          display: false,
        },
        tooltip: false,
        responsive: true,
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Valuation',
        },
      },
      scales: {
        y: {
          stacked: true,
        },
      },
      barThickness: 30,
    }

    const safetyMargin = (fairPrice - appContext.priceData.price) / fairPrice
    const safetyMargin35 =
      fairPrice < 0
        ? fairPrice + fairPrice * 0.35
        : fairPrice - fairPrice * 0.35
    const safetyMargin0 = fairPrice
    const safetyMarginMinus35 =
      fairPrice < 0
        ? safetyMargin0 - safetyMargin0 * 0.35
        : safetyMargin0 + safetyMargin0 * 0.35

    const customOptions = (color) => ({
      borderColor: color,
      backgroundColor: getTransparentColor(color),
      borderWidth: 1.3,
      borderRadius: 0,
      tension: 0.3,
    })

    const data = {
      labels: [''],
      datasets: [
        {
          label: 'Current Price',
          data: [appContext.priceData.price.toFixed(2)],
          backgroundColor: getTransparentColor('#fff'),
          borderWidth: 1,
          borderColor: '#333',
          type: 'line',
          radius: 10,
          order: 2,
          datalabels: {
            formatter: function (value) {
              return currencyFormatter.format(value) + '\nCurrent Price'
            },
            textAlign: 'center',
            display: true,
            anchor: 'enter',
            align: 'bottom',
            offset: 20,
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
        },
        // {
        //   label: 'Fair Value',
        //   data: [fairPrice.toFixed(2)],
        //   borderColor: '#333',
        //   backgroundColor: '#fff',
        //   borderWidth: 1,
        //   type: 'line',
        //   radius: 10,
        //   order: 1,
        //   datalabels: {
        //     formatter: function (value) {
        //       return currencyFormatter.format(value) + '\nFair Value'
        //     },
        //     textAlign: 'center',
        //     display: true,
        //     anchor: 'center',
        //     align: 'top',
        //     offset: 40,
        //     labels: {
        //       title: {
        //         font: {
        //           weight: 'bold',
        //         },
        //       },
        //     },
        //   },
        // },
        {
          label: 'Undervalued',
          data: [safetyMargin35.toFixed(2)],
          ...customOptions(green),
          order: 3,
          base: fairPrice < 0 ? safetyMargin0 + safetyMargin35 : 0,
          // datalabels: {
          //   formatter: function () {
          //     return '35%\nMargin Of Safety'
          //   },
          //   textAlign: 'right',
          //   display: true,
          //   anchor: 'end',
          //   align: 'left',
          //   offset: 0,
          //   labels: {
          //     title: {
          //       font: {
          //         weight: 'bold',
          //       },
          //     },
          //   },
          //   backgroundColor: getTransparentColor(green5),
          //   color: '#fff',
          // },
          // datalabels: {
          //   formatter: function (value) {
          //     return (
          //       currencyFormatter.format(safetyMargin35) +
          //       '\n@ 35% Safety Margin'
          //     )
          //   },
          //   textAlign: 'center',
          //   display: true,
          //   anchor: 'end',
          //   align: 'top',
          //   offset: 20,
          //   labels: {
          //     title: {
          //       font: {
          //         weight: 'bold',
          //       },
          //     },
          //   },
          // },
        },
        {
          label: 'Fair Value',
          data: [safetyMargin0.toFixed(2)],
          order: 4,
          ...customOptions(yellow),
          base: safetyMargin35.toFixed(2),
          datalabels: {
            formatter: function (value) {
              return currencyFormatter.format(value) + '\nFair Value'
            },
            textAlign: 'center',
            display: true,
            anchor: 'end',
            align: 'top',
            offset: 20,
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
        },
        {
          label: 'Overvalued',
          data: [
            safetyMarginMinus35 < appContext.priceData.price
              ? (
                  appContext.priceData.price +
                  appContext.priceData.price * 0.05
                ).toFixed(2)
              : safetyMarginMinus35.toFixed(2),
          ],
          order: 5,
          ...customOptions(red),
          base: safetyMargin0.toFixed(2),
        },
      ],
    }

    const industriesAverages = appContext.getAveragesByIndustry(
      appContext.stockData.financials.industry
    )

    return (
      <Fragment>
        <Stack direction="row" justifyContent="center">
          <BigNumber
            value="Fair Value"
            label={currencyFormatter.format(fairPrice)}
          />
          <BigNumber
            value="Current Price"
            label={currencyFormatter.format(appContext.priceData.price)}
          />
          <BigNumber
            value="Undervalued"
            label={(safetyMargin * 100).toFixed(2) + '%'}
          />
          {/* {appContext.stockData &&
            industriesAverages &&
            industriesAverages.avgMarginOfSafety && (
              <BigNumber
                value="vs Industry"
                label={industriesAverages.avgMarginOfSafety.toFixed(2) + '%'}
              />
            )} */}
        </Stack>
        <div id="intrinsic-value" className="chart">
          <Bar options={options} data={data} plugins={[ChartDataLabels]} />
        </div>
      </Fragment>
    )
  }

  const renderGordonGrowthDetails = (gordon) => {
    return (
      <Fragment>
        {renderValuationDetails(gordon.result)}
        {renderCollapsibleDetails(
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={2}
            textAlign="left"
          >
            <Grid item sm={4}>
              Dividends Per Share{' '}
              <Chip label={roundFloat(gordon.dividendPerShare)} />
            </Grid>
            <Grid item sm={4}>
              Dividend Growth Rate{' '}
              <Chip label={roundFloat(gordon.dividendGrowthRate)} />
            </Grid>
            <Grid item sm={4}>
              Required Rate of Return{' '}
              <Chip label={roundFloat(gordon.requiredRateOfReturn)} />
            </Grid>
          </Grid>
        )}
      </Fragment>
    )
  }

  const renderVsModelDetails = (vsModel) => {
    const input =
      vsModel.normal.variables.inputType === 'earnings'
        ? 'Earnings'
        : 'Free Cash Flow'
    const data = {
      labels: vsModel.normal.results.years,
      datasets: [
        {
          label: 'Average ' + input,
          data: vsModel.normal.results.inputOfYears,
          ...barChartOptions(yellow),
        },
        {
          label: 'Pessimistic ' + input,
          data: vsModel.bad.results.inputOfYears,
          ...barChartOptions(red),
        },
        {
          label: 'Optimistic ' + input,
          data: vsModel.good.results.inputOfYears,
          ...barChartOptions(green),
        },
      ],
    }

    return (
      <Fragment>
        {renderValuationDetails(vsModel.price)}
        {renderCollapsibleDetails(
          <Fragment>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              textAlign="left"
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell>Year</TableCell>
                    <TableCell>Growth Rate</TableCell>
                    <TableCell>
                      {vsModel.normal.variables.inputType === 'fcf'
                        ? 'Free Cash Flow'
                        : 'Earnings'}
                    </TableCell>
                    <TableCell>Present Value</TableCell>
                  </TableHead>
                  <TableBody>
                    {vsModel.normal.variables.growthRates.map((v, index) => (
                      <TableRow>
                        <TableCell>
                          {index ===
                          vsModel.normal.variables.growthRates.length - 1
                            ? 'Terminal'
                            : `Year ${index + 1}`}
                        </TableCell>
                        <TableCell>{v.toFixed(3)}</TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            vsModel.normal.results.inputOfYears[index]
                          )}
                        </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            vsModel.normal.results.discountRateOverYears[index]
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell>Year</TableCell>
                    <TableCell>Growth Rate</TableCell>
                    <TableCell>
                      {vsModel.bad.variables.inputType === 'fcf'
                        ? 'Free Cash Flow'
                        : 'Earnings'}
                    </TableCell>
                    <TableCell>Present Value</TableCell>
                  </TableHead>
                  <TableBody>
                    {vsModel.bad.variables.growthRates.map((v, index) => (
                      <TableRow>
                        <TableCell>
                          {index ===
                          vsModel.bad.variables.growthRates.length - 1
                            ? 'Terminal'
                            : `Year ${index + 1}`}
                        </TableCell>
                        <TableCell>{v.toFixed(3)}</TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            vsModel.bad.results.inputOfYears[index]
                          )}
                        </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            vsModel.bad.results.discountRateOverYears[index]
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell>Year</TableCell>
                    <TableCell>Growth Rate</TableCell>
                    <TableCell>
                      {vsModel.good.variables.inputType === 'fcf'
                        ? 'Free Cash Flow'
                        : 'Earnings'}
                    </TableCell>
                    <TableCell>Present Value</TableCell>
                  </TableHead>
                  <TableBody>
                    {vsModel.good.variables.growthRates.map((v, index) => (
                      <TableRow>
                        <TableCell>
                          {index ===
                          vsModel.good.variables.growthRates.length - 1
                            ? 'Terminal'
                            : `Year ${index + 1}`}
                        </TableCell>
                        <TableCell>{v.toFixed(3)}</TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            vsModel.good.results.inputOfYears[index]
                          )}
                        </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            vsModel.good.results.discountRateOverYears[index]
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Line data={data} />
          </Fragment>
        )}
      </Fragment>
    )
  }

  const renderCollapsibleDetails = (children) => (
    <Accordion variant="outline">
      <AccordionSummary
        expandIcon={<span className="material-icons">expand_more</span>}
      >
        Details
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )

  const renderGrahamDetails = (graham) => (
    <Fragment>
      {renderValuationDetails(graham.result)}
      {renderCollapsibleDetails(
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={2}
          textAlign="left"
        >
          <Grid item sm={6}>
            Earnings Per Share (TTM) <Chip label={graham.earningsPerShareTTM} />
          </Grid>
          <Grid item sm={6}>
            Price to earnings (No Growth){' '}
            <Chip label={graham.priceToEarningsNoGrowth} />
          </Grid>
          <Grid item sm={6}>
            Future EPS Growth <Chip label={graham.futureEPSGrowth} />
          </Grid>
          <Grid item sm={6}>
            20-Years Bond Yield <Chip label={graham.current20YearBondYield} />
          </Grid>
        </Grid>
      )}
    </Fragment>
  )

  const renderDCFDetails = (dcf) => {
    // const data = {
    //   labels: dcf.futureFreeCashFlows.map((v, i) => `Year ${i + 1}`),
    //   datasets: [
    //     {
    //       label: 'Free Cash Flow',
    //       data: dcf.futureFreeCashFlows,
    //       ...barChartOptions(yellow),
    //     },
    //     {
    //       label: 'Present Value',
    //       data: dcf.futurePresentValues,
    //       ...barChartOptions(green),
    //     },
    //   ],
    // }
    return (
      <Fragment>
        {renderValuationDetails(dcf.result)}
        {renderCollapsibleDetails(
          <Fragment>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
              spacing={2}
              className="data-grid"
              textAlign="left"
            >
              <Grid item sm={6}>
                Free Cash Flow <Chip label={dcf.freeCashFlow} />
              </Grid>
              <Grid item sm={6}>
                Discount Rate <Chip label={roundFloat(dcf.discountRate)} />
              </Grid>
              <Grid item sm={6}>
                Expected FCF Growth Rate{' '}
                <Chip label={roundFloat(dcf.fcfGrowthRate)} />
              </Grid>
              <Grid item sm={6}>
                Perpetual FCF Growth Rate{' '}
                <Chip label={roundFloat(dcf.fcfPerpetualGrowthRate)} />
              </Grid>
              <Grid item sm={6}>
                Cash and equivalent <Chip label={dcf.cashAndCashEquivalent} />
              </Grid>
              <Grid item sm={6}>
                Total Debt <Chip label={dcf.totalDebt} />
              </Grid>
              <Grid item sm={12}>
                Shares Outstanding <Chip label={dcf.sharesOutstanding} />
              </Grid>

              <Table>
                <TableHead>
                  <TableCell>Year</TableCell>
                  <TableCell>Free Cash Flow</TableCell>
                  <TableCell>Present Value</TableCell>
                </TableHead>
                <TableBody>
                  {dcf.futureFreeCashFlows.map((v, index) => (
                    <TableRow>
                      <TableCell>
                        {index === dcf.futureFreeCashFlows.length - 1
                          ? 'Terminal'
                          : `Year ${index + 1}`}
                      </TableCell>
                      <TableCell>{currencyFormatter.format(v)}</TableCell>
                      <TableCell>
                        {currencyFormatter.format(
                          dcf.futurePresentValues[index]
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            {/* <Line data={data} /> */}
          </Fragment>
        )}
      </Fragment>
    )
  }

  const renderDDMDetails = (valuations) => (
    <Fragment>
      {renderValuationDetails(valuations.ddm.result)}
      {renderCollapsibleDetails(
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          textAlign="left"
        >
          <Grid item xs={6} sm={6}>
            Dividend Per Share <Chip label={valuations.ddm.dividendPerShare} />
          </Grid>
          <Grid item xs={6} sm={6}>
            Cost of equity{' '}
            <Chip label={roundFloat(valuations.ddm.costOfEquity)} />
          </Grid>
          <Grid item xs={6} sm={6}>
            High Growth Rate{' '}
            <Chip label={roundFloat(valuations.ddm.dividendGrowthRateStage1)} />
          </Grid>
          <Grid item xs={6} sm={6}>
            Stable Growth Rate{' '}
            <Chip label={roundFloat(valuations.ddm.dividendGrowthRateStage2)} />
          </Grid>
          <Table>
            <TableHead>
              <TableCell>Year</TableCell>
              <TableCell>Dividends per share</TableCell>
              <TableCell>Present Value</TableCell>
            </TableHead>
            <TableBody>
              {valuations.ddm.futureDividendsPerShare.map((v, index) => (
                <TableRow>
                  <TableCell>
                    {index === valuations.ddm.futureDividendsPerShare.length - 1
                      ? 'Terminal'
                      : `Year ${index + 1}`}
                  </TableCell>
                  <TableCell>{currencyFormatter.format(v)}</TableCell>
                  <TableCell>
                    {currencyFormatter.format(
                      valuations.ddm.futurePresentValues[index]
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </Fragment>
  )

  const renderFinancialsChart = () => {
    if (appContext.stockData) {
      const preparedData = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Revenue',
            data: getMetricForPeriod('revenue').map(inBillions),
            ...barChartOptions(blue),
          },
          {
            label: 'Gross Profit',
            data: getMetricForPeriod('gross_profit').map(inBillions),
            ...barChartOptions(orange),
          },
          {
            label: 'Operating Income',
            data: getMetricForPeriod('operating_income').map(inBillions),
            ...barChartOptions(teal),
          },
          {
            label: 'Free Cashflow',
            data: getMetricForPeriod('fcf').map(inBillions),
            ...barChartOptions(green),
          },
          {
            label: 'Long-term Debt',
            data: getMetricForPeriod('lt_debt').map(inBillions),
            ...barChartOptions(red),
          },
        ],
      }

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            intersect: false,
            mode: 'index',
            padding: 12,
            displayColors: true,
          },
          responsive: true,
          legend: {
            position: 'top',
          },
          title: {
            display: false,
            text: 'Financials (Billions)',
          },
          // zoom: {
          //   pan: {
          //     enabled: true,
          //     mode: 'x',
          //   },
          //   limits: {
          //     x: {
          //       minRange: 2,
          //     },
          //   },
          //   zoom: {
          //     wheel: {
          //       enabled: true,
          //     },
          //     overScaleMode: 'y',
          //   },
          // },
        },
      }

      return (
        <div className="chart interactive" style={{ minHeight: '350px' }}>
          <Line
            options={{ ...options }}
            plugins={chartLinePlugin}
            data={preparedData}
          />
        </div>
      )
    }
  }

  const renderPriceChart = () => {
    if (appContext.stockData && appContext.priceData) {
      const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            intersect: false,
            mode: 'index',
            padding: 12,
            displayColors: true,
          },
          legend: {
            position: 'top',
          },
        },
      }

      const data = {
        labels: [...getMetricForPeriod('period_end_date'), 'Now'],
        datasets: [
          {
            label: 'Price per share',
            data: [
              ...getMetricForPeriod('period_end_price'),
              appContext.priceData.price,
            ],
            ...barChartOptions(green),
            fill: true,
          },
        ],
      }

      return (
        <div className="chart interactive" style={{ minHeight: '350px' }}>
          <Line options={options} plugins={chartLinePlugin} data={data} />
        </div>
      )
    }
  }

  const renderOutstandingShares = () => {
    if (appContext.stockData) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Outstanding Shares (millions)',
            data: getMetricForPeriod('shares_eop').map(inMillions),
            ...barChartOptions(teal),
          },
        ],
      }

      return (
        <div id="estimated-growth" className="chart">
          <Bar options={{ ...chartOptions, fill: true }} data={data} />
        </div>
      )
    }
  }

  const renderPayoutRatio = () => {
    if (appContext.stockData) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Dividend Payout Ratio',
            data: getMetricForPeriod('payout_ratio'),
            ...barChartOptions(yellow),
          },
        ],
      }

      return (
        <div id="payout-ratio" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  const renderEPSChart = () => {
    if (appContext.stockData) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Earnings per share',
            data: getMetricForPeriod('eps_basic'),
            ...barChartOptions(blue),
          },
        ],
      }

      return (
        <div id="eps" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  const renderDPSChart = () => {
    if (appContext.stockData) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Dividends per share',
            data: getMetricForPeriod('dividends'),
            ...barChartOptions(orange),
          },
        ],
      }

      return (
        <div id="eps" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  const renderKeyMetricsSection = () => {
    if (appContext.stockData && appContext.priceData) {
      return (
        <Grid container columnSpacing="2rem">
          <Grid item md={6} sm={6} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Market Cap</TableCell>
                    <TableCell>
                      {currencyFormatter.format(
                        inBillions(
                          last(
                            appContext.stockData.financials.quarterly.market_cap
                          )
                        )
                      )}
                      B
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>EPS (TTM)</TableCell>
                    <TableCell>
                      {currencyFormatter.format(
                        appContext.stockData.financials.ttm.eps_basic
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>ROIC</TableCell>
                    <TableCell>
                      {last(
                        appContext.stockData.financials.annual.roic
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>ROCE </TableCell>
                    <TableCell>
                      {last(
                        appContext.stockData.financials.annual.roce
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>D/E</TableCell>
                    <TableCell>
                      {appContext.stockData.financials.ttm.debt_to_equity.toFixed(
                        2
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>P/E</TableCell>
                    <TableCell>
                      {appContext.stockData.financials.ttm.price_to_earnings.toFixed(
                        2
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>P/S</TableCell>
                    <TableCell>
                      {appContext.stockData.financials.ttm.price_to_sales.toFixed(
                        2
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Yield</TableCell>
                    <TableCell>
                      {appContext.stockData.financials.annual?.dividends &&
                        appContext.priceData &&
                        (
                          (last(
                            appContext.stockData.financials.annual.dividends
                          ) /
                            appContext.priceData.price) *
                          100
                        ).toFixed(1) + '%'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Industry</TableCell>
                    <TableCell>
                      {appContext.stockData.financials.sector}
                      {' / '}
                      {appContext.stockData.financials.industry}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Exchange</TableCell>
                    <TableCell>
                      {appContext.stockData.financials.exchange}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Last Price</TableCell>
                    <TableCell>
                      {currencyFormatter.format(appContext.priceData.price)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )
    }
  }

  function ShowOnScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    })

    return (
      <Slide appear={false} direction="down" in={trigger}>
        {children}
      </Slide>
    )
  }

  const renderMenu = () => {
    if (appContext.stockData) {
      return (
        <ShowOnScroll>
          <AppBar variant="outlined" position="fixed" color="secondary">
            <Toolbar style={{ justifyContent: 'center' }}>
              <Stack direction="horizontal">
                <Button href="#scores" color="primary">
                  <span className="material-icons">donut_large</span> Scores
                </Button>
                <Button href="#price-per-share" color="primary">
                  <span class="material-icons">attach_money</span> Price
                </Button>
                <Button href="#income-statements" color="primary">
                  <span class="material-icons">account_balance</span> Income
                </Button>
                <Button href="#financials" color="primary">
                  <span class="material-icons">bar_chart</span> Financials
                </Button>
                <Button href="#margins" color="primary">
                  <span class="material-icons">percent</span> Margins
                </Button>
                <Button href="#valuation" color="primary">
                  <span class="material-icons">request_quote</span> Valuations
                </Button>
                <Button href="#industry" color="primary">
                  <span class="material-icons">factory</span> Industry
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
        </ShowOnScroll>
      )
    }
  }

  const renderName = () => {
    if (appContext.stockData) {
      const companyName = appContext.stockData.financials.name

      return (
        <Fragment>
          <Bookmarks
            symbol={appContext.symbol}
            location={appContext.location}
            companyName={companyName}
          />
          {companyName} <TotalScore score={appContext.stockData.mighty_score} />
        </Fragment>
      )
    }
  }

  const renderLoading = () => {
    return (
      <div className="loading-section">
        <Loading />
      </div>
    )
  }

  const renderPricesToEarnings = () => {
    if (appContext.stockData) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Price to earnings',
            data: getMetricForPeriod('price_to_earnings'),
            ...barChartOptions(green),
          },
        ],
      }

      return (
        <div id="pe" className="chart">
          <Bar options={{ ...chartOptions, ...logarithmicScale }} data={data} />
        </div>
      )
    }
  }

  const renderDebtToEquity = () => {
    if (appContext.stockData) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Debt to equity',
            data: getMetricForPeriod('debt_to_equity'),
            ...barChartOptions(red),
          },
        ],
      }

      return (
        <div id="dps" className="chart">
          <Bar options={{ ...chartOptions }} data={data} />
        </div>
      )
    }
  }

  const renderFcfMarginIndustry = () => {
    if (appContext.stockData) {
      const industriesAverages = appContext.getAveragesByIndustry(
        appContext.stockData.financials.industry
      )

      if (!industriesAverages) {
        return
      }

      const data = {
        labels: ['Industry', symbol],
        datasets: [
          {
            label: 'FCF Margin',
            data: [
              industriesAverages.avgFcfMargin,
              mean(appContext.stockData.financials.annual.fcf_margin ?? []),
            ],
            ...barChartOptions(green),
          },
        ],
      }

      return (
        <div id="fcf-industry" className="chart">
          <Bar options={{ ...chartOptions }} data={data} />
        </div>
      )
    }
  }

  const renderOperatingMarginIndustry = () => {
    if (appContext.stockData) {
      const industriesAverages = appContext.getAveragesByIndustry(
        appContext.stockData.financials.industry
      )

      if (!industriesAverages) {
        return
      }

      const data = {
        labels: ['Industry', symbol],
        datasets: [
          {
            label: 'Operating Margin',
            data: [
              industriesAverages.avgOperatingMargin,
              mean(
                appContext.stockData.financials.annual.operating_margin ?? []
              ),
            ],
            ...barChartOptions(teal),
          },
        ],
      }

      return (
        <div id="fcf-industry" className="chart">
          <Bar options={{ ...chartOptions }} data={data} />
        </div>
      )
    }
  }

  const renderGrossMarginIndustry = () => {
    if (appContext.stockData) {
      const industriesAverages = appContext.getAveragesByIndustry(
        appContext.stockData.financials.industry
      )

      if (!industriesAverages) {
        return
      }

      const data = {
        labels: ['Industry', symbol],
        datasets: [
          {
            label: 'Gross Margin',
            data: [
              industriesAverages.avgGrossMargin,
              mean(appContext.stockData.financials.annual.gross_margin ?? []),
            ],
            ...barChartOptions(yellow),
          },
        ],
      }

      return (
        <div id="fcf-industry" className="chart">
          <Bar options={{ ...chartOptions }} data={data} />
        </div>
      )
    }
  }

  const renderNetIncomeMarginIndustry = () => {
    if (appContext.stockData) {
      const industriesAverages = appContext.getAveragesByIndustry(
        appContext.stockData.financials.industry
      )

      if (!industriesAverages) {
        return
      }

      const data = {
        labels: ['Industry', symbol],
        datasets: [
          {
            label: 'Net Income Margin',
            data: [
              industriesAverages.avgNetIncomeMargin,
              mean(
                appContext.stockData.financials.annual.net_income_margin ?? []
              ),
            ],
            ...barChartOptions(blue),
          },
        ],
      }

      return (
        <div id="fcf-industry" className="chart">
          <Bar options={{ ...chartOptions }} data={data} />
        </div>
      )
    }
  }

  const handlePeriodChange = () => {
    if (period === 'annual') {
      setPeriod('quarterly')
    } else if (period === 'quarterly') {
      setPeriod('annual')
    }
  }

  const handleToggleShowLast5years = () => {
    setShowOnlyLast5Years(!showOnlyLast5years)
  }

  const renderPeriodChangeButton = () => {
    return (
      !appContext.loading && (
        <div className="period-change-section">
          <Toggle
            checkedLabel="Quarterly"
            uncheckedLabel="Annual"
            onChange={handlePeriodChange}
            checked={period === 'quarterly'}
          />
          <Toggle
            title={'Change years range'}
            checked={showOnlyLast5years}
            checkedLabel="Last 5 Years"
            uncheckedLabel="All Years"
            onChange={handleToggleShowLast5years}
          />
        </div>
      )
    )
  }

  const renderEBITDA = () => {
    if (appContext.stockData && getMetricForPeriod('ebitda').length > 0) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'EBITDA',
            data: getMetricForPeriod('ebitda').map(inBillions),
            ...barChartOptions(green5),
          },
        ],
      }

      return (
        <div id="ebitda" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  const renderCAPEX = () => {
    if (appContext.stockData && getMetricForPeriod('capex').length > 0) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'CapEx',
            data: getMetricForPeriod('capex')
              .map((x) => -x)
              .map(inBillions),
            ...barChartOptions(majenta),
          },
        ],
      }

      return (
        <div id="capex" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  const renderROCE = () => {
    if (appContext.stockData && getMetricForPeriod('roce').length > 0) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'ROCE',
            data: getMetricForPeriod('roce'),
            ...barChartOptions(blue),
          },
        ],
      }

      return (
        <div id="roce" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  const renderROIC = () => {
    if (appContext.stockData && getMetricForPeriod('roic').length > 0) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'ROIC',
            data: getMetricForPeriod('roic'),
            ...barChartOptions(orange),
          },
        ],
      }

      return (
        <div id="roic" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  const renderDebtToAssets = () => {
    if (
      appContext.stockData &&
      getMetricForPeriod('debt_to_assets').length > 0
    ) {
      const data = {
        labels: getMetricForPeriod('period_end_date'),
        datasets: [
          {
            label: 'Debt to assets',
            data: getMetricForPeriod('debt_to_assets'),
            ...barChartOptions(red),
          },
        ],
      }

      return (
        <div id="debt-to-assets" className="chart">
          <Bar options={chartOptions} data={data} />
        </div>
      )
    }
  }

  if (appContext.failedToFetch) {
    return <Oops symbol={symbol} />
  }

  if (appContext.loading || !appContext.stockData) {
    return renderLoading()
  }

  const renderDetails = (details) => {
    if (showMore) {
      return (
        <>
          {details}
          &nbsp;
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => showLess(!showMore)}
          >
            read less
          </span>
        </>
      )
    }

    return (
      <>
        <span>{details.slice(0, 250)}...&nbsp;</span>
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => showLess(!showMore)}
        >
          read more
        </span>
      </>
    )
  }

  const modalStyle = lgMediaQuery
    ? {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 4,
      }
    : {
        position: 'absolute',
        top: '0',
        left: '0',
        p: 2,
      }

  const renderTitle = () => {
    return (
      <Card variant="outline" id="scores">
        <CardHeader title={renderName()} />
        <CardContent>
          <Card variant="outline">
            <Typography textAlign="left" style={{ padding: '1rem' }}>
              {renderDetails(appContext.stockData.financials.description)}
            </Typography>
          </Card>
          <Card variant="outline" className="paper-div">
            {appContext.stockData && (
              <Fragment>
                <ScoreDetailSectionComponent
                  zscore={appContext.stockData.z_score}
                  fscore={appContext.stockData.f_score}
                  vscore={appContext.stockData.v_score}
                  safety={appContext.stockData.safety_margin}
                  fairValue={
                    appContext.valuations && appContext.valuations.fair_price
                  }
                  price={appContext.priceData && appContext.priceData.price}
                  expanded={false}
                />
                <div
                  onClick={handleExpandScoreDetails}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {scoreDetailsExpanded ? 'Hide details' : 'See details'}
                </div>
                <Modal
                  style={{ overflowY: 'scroll' }}
                  id="scode-details-modal"
                  open={scoreDetailsExpanded}
                  onClose={() => setScoreDetailsExpanded(false)}
                >
                  <Box sx={modalStyle}>
                    <Card className="explain-modal" variant="outline">
                      <CardContent>
                        <Stack
                          direction="horizontal"
                          justifyContent="space-between"
                        >
                          <h3>Score Details</h3>
                          <IconButton
                            onClick={() =>
                              setScoreDetailsExpanded(!scoreDetailsExpanded)
                            }
                          >
                            <span className="material-icons">close</span>
                          </IconButton>
                        </Stack>
                        <ScoreDetailSectionComponent
                          zscore={appContext.stockData.z_score}
                          fscore={appContext.stockData.f_score}
                          vscore={appContext.stockData.v_score}
                          safety={appContext.stockData.safety_margin}
                          fairValue={
                            appContext.valuations &&
                            appContext.valuations.fair_price
                          }
                          price={
                            appContext.priceData && appContext.priceData.price
                          }
                          expanded
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </Modal>
              </Fragment>
            )}
          </Card>
        </CardContent>
      </Card>
    )
  }

  const handleExpandScoreDetails = () => {
    setScoreDetailsExpanded(!scoreDetailsExpanded)
  }

  return (
    <ErrorBoundary>
      <SubscriptionGuard>
        <AutoScrollToTop />
        {lgMediaQuery && renderMenu()}
        <div className="App">
          {!appContext.loading && !appContext.stockData && (
            <Oops symbol={symbol} />
          )}

          <Grid
            container
            spacing={lgMediaQuery ? '2rem' : '1rem'}
            padding={lgMediaQuery ? '2rem' : '0'}
          >
            <Grid item lg={6} md={12} sm={12} xs={12}>
              {renderTitle()}
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper variant="outline">{renderKeyMetricsSection()}</Paper>
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Card variant="outline">
                <CardHeader title="Price per share" id="price-per-share" />
                <CardContent>{renderPriceChart()}</CardContent>
              </Card>
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Card variant="outline">
                <CardHeader
                  title="Income Statements (Billions)"
                  id="income-statements"
                />
                <CardContent>
                  {renderFinancialsChart()}
                  {renderPeriodChangeButton()}
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card variant="outline">
                <CardHeader title="Financials" id="financials" />
                <CardContent>
                  <Grid container spacing="2rem">
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderPricesToEarnings()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderDebtToEquity()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderEPSChart()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderDPSChart()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderPayoutRatio()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderOutstandingShares()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderEBITDA()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderCAPEX()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderROCE()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderROIC()}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12}>
                      {renderDebtToAssets()}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Card variant="outline">
                <CardHeader title="Margins" id="margins" />
                <CardContent>
                  <Grid container spacing="2rem">
                    <Grid item xl={6} lg={6} md={6} sm={12}>
                      {renderNetIncomeMarginIndustry()}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12}>
                      {renderGrossMarginIndustry()}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12}>
                      {renderOperatingMarginIndustry()}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12}>
                      {renderFcfMarginIndustry()}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Card variant="outline">
                <CardHeader title="Valuation" id="valuation" />
                <CardContent>{renderValuationsDetails()}</CardContent>
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              {renderCompetitors()}
            </Grid>
          </Grid>
          <BackToTop />
        </div>
      </SubscriptionGuard>
    </ErrorBoundary>
  )
}

export default Home
