import { ApiClient } from '../network/ApiClient'
import { useAuthContext } from '../contexts/AuthContext'
import BookmarkWidget from './BookmarkWidget'
import { useState } from 'react'
import * as tracking from '../services/tracking'

const Bookmarks = ({ symbol, location, companyName }) => {
  const authContext = useAuthContext()
  const [loading, setLoading] = useState(false)
  const apiClient = new ApiClient()
  const ticker = `${symbol}:${location}`

  const handleOnSave = async () => {
    setLoading(true)
    tracking.bookmark(ticker)
    await apiClient.addBookmark(ticker)
    await refresh()
  }

  const handleOnDelete = async () => {
    setLoading(true)
    await apiClient.removeBookmark(symbol, location)
    await refresh()
  }

  const refresh = async () =>
    await apiClient
      .getBookmarks()
      .then((data) => authContext.setBookmarks(data.bookmarks))
      .finally(() => setLoading(false))

  return (
    <BookmarkWidget
      bookmarks={authContext.bookmarks}
      onSave={handleOnSave}
      onDelete={handleOnDelete}
      symbol={symbol}
      location={location}
      companyName={companyName}
      loading={loading}
    />
  )
}

export default Bookmarks
