import { PayPalButtons } from '@paypal/react-paypal-js'
import { useAuthContext } from '../contexts/AuthContext'
import './Payment.css'

export function Payment({
  onApprove = (subscriptionID) => {},
  planId,
  onError = (error) => {},
  onCancel = (data) => {},
}) {
  const authContext = useAuthContext()

  return (
    <div className="payment-wrapper">
      <PayPalButtons
        disabled={!authContext.signedIn}
        style={{ layout: 'vertical', label: 'pay' }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: planId,
            quantity: 1,
          })
        }}
        onCancel={(data, actions) => {
          onCancel(data)
        }}
        onError={(err) => {
          onError(err)
        }}
        onApprove={(data, actions) => {
          onApprove(data.subscriptionID)
        }}
      />
    </div>
  )
}
