import { FinancialBadge } from './FinancialsBadge'
import { CreditBadge } from './CreditBadge'
import { useNavigate } from 'react-router-dom'
import { MarginOfSafety } from './PriceScore'
import { GrowthBadge } from './GrowthBadge'
import { Card, Grid, TableCell, TableRow } from '@mui/material'
import { TotalScore } from './TotalScore'
import { Fragment } from 'react'
import { green, red } from '../utils/Colors'
import { ScoreDetailSectionComponentInline } from './ScoreDetailsComponent'

const handleClick = (e, cb) => {
  e.preventDefault()
  window.scrollTo({ top: 0 })
  cb()
}

export const StockItem = ({ stock, lg, md }) => {
  const parts = stock.ticker.split(':')
  const url = `/analysis/${parts[0]}/${parts[1]}`
  const navigate = useNavigate()

  const calculateDiffPercent = (v1, v2) => {
    const r = (v1 - v2) / v1
    return isNaN(r) ? 'NA' : (r * 100).toFixed(2) + '%'
  }

  if (lg) {
    return (
      <TableRow
        className="stock-item"
        to={url}
        onClick={(e) => handleClick(e, () => navigate(url))}
        key={stock.ticker}
      >
        <TableCell>
          <b>{parts[0]}</b>
        </TableCell>

        <TableCell style={{ textAlign: 'left' }}>
          <span>{stock.data.metadata.name}</span>
        </TableCell>
        <TableCell style={{ textAlign: 'left' }}>
          <span>{stock.data.metadata.morningstar_industry}</span>
        </TableCell>

        {stock.mighty_score && (
          <TableCell
            className="mighty-score"
            align="center"
            style={{ textAlign: 'left', width: '125px' }}
          >
            <TotalScore score={stock.mighty_score} />
            {stock.mighty_score === stock.previous_mighty_score ||
            !stock.previous_mighty_score ? (
              <Fragment>
                <span className="material-icons" style={{ color: 'gray' }}>
                  arrow_drop_up
                </span>
                <span style={{ color: 'gray' }}>0.00%</span>
              </Fragment>
            ) : stock.mighty_score < stock.previous_mighty_score ? (
              <Fragment>
                <span className="material-icons" style={{ color: red }}>
                  arrow_drop_down
                </span>
                <span style={{ color: red }}>
                  {calculateDiffPercent(
                    stock.mighty_score,
                    stock.previous_mighty_score
                  )}
                </span>
              </Fragment>
            ) : (
              <Fragment>
                <span className="material-icons" style={{ color: green }}>
                  arrow_drop_up
                </span>
                <span style={{ color: green }}>
                  {calculateDiffPercent(
                    stock.mighty_score,
                    stock.previous_mighty_score
                  )}
                </span>
              </Fragment>
            )}
          </TableCell>
        )}
        {stock.safety_margin !== null && (
          <TableCell align="center">
            <MarginOfSafety score={stock.safety_margin} />
          </TableCell>
        )}
        {stock.v_score && (
          <TableCell align="center">
            <GrowthBadge score={stock.v_score.totalScore} />
          </TableCell>
        )}
        {stock.f_score && (
          <TableCell align="center">
            <FinancialBadge score={stock.f_score.totalScore} />
          </TableCell>
        )}
        {stock.z_score && (
          <TableCell align="center">
            <CreditBadge score={stock.z_score.score} />
          </TableCell>
        )}
      </TableRow>
    )
  }

  return (
    <Card style={{ padding: '0.5rem', margin: '0.5rem' }} key={stock.ticker}>
      <Grid
        container
        to={url}
        onClick={(e) => handleClick(e, () => navigate(url))}
        spacing="1rem"
      >
        <Grid item sm={4} xs={4} style={{ textAlign: 'left' }}>
          <TotalScore score={stock.mighty_score} /> <b>{parts[0]}</b>
        </Grid>
        <Grid item sm={8} xs={8} style={{ textAlign: 'right' }}>
          <span>{stock.data.metadata.morningstar_industry}</span>
        </Grid>
        <Grid item sm={12} xs={12} style={{ textAlign: 'center' }}>
          <span>{stock.data.metadata.name}</span>
        </Grid>
        <Grid item sm={12} xs={12} justifyContent="center" alignItems="center">
          {stock.z_score &&
            stock.f_score &&
            stock.v_score &&
            stock.safety_margin && (
              <ScoreDetailSectionComponentInline
                zscore={stock.z_score}
                fscore={stock.f_score}
                vscore={stock.v_score}
                safety={stock.safety_margin}
              />
            )}
        </Grid>
      </Grid>
    </Card>
  )
}
