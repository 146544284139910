import { Box, Card, CardContent, Chip, Modal } from '@mui/material'
import { Fragment, useState } from 'react'

export default function Explain({ description, title }) {
  const [anchorEl, setAnchorEl] = useState(undefined)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
  }

  return (
    <Fragment>
      <Chip
        size="small"
        aria-describedby={id}
        label="?"
        onClick={handleClick}
      />
      <Modal
        id={id}
        open={open}
        // anchorEl={anchorEl}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'center',
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'center',
        // }}
      >
        <Box sx={style}>
          <Card className="explain-modal" variant="outline">
            <CardContent>
              <h3>{title}</h3>
              {description}
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Fragment>
  )
}
