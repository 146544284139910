import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import { Loading } from './Loading'
import { getCurrentUser } from '../services/auth'

export default function SubscriptionGuard(props) {
  const authContext = useAuthContext()
  const location = useLocation()

  // if (!authContext.signedIn) {
  //   console.log('Not Signed In')
  //   return <Navigate to="/signin" />
  // }

  // if (authContext.account && authContext.account.expired) {
  //   return <Navigate to="/account" />
  // }

  return props.children
}
