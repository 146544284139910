import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'

const checkMarkSVG = (
  <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
    <path
      class="checkmark__check"
      fill="none"
      d="M14.1 27.2l7.1 7.2 16.7-16.8"
    />
  </svg>
)

export const SignupForm = ({
  onEmailChange,
  onPasswordChange,
  onClose,
  onSignup,
  onSignUpWithGoogle,
  onSignUpWithFacebook,
}) => {
  const authContext = useAuthContext()

  const renderFormSection = () => {
    return authContext.signedIn ? (
      <div>{checkMarkSVG}</div>
    ) : (
      <Fragment>
        <button
          type="button"
          className="signin-google"
          onClick={onSignUpWithGoogle}
        >
          <img src={process.env.PUBLIC_URL + '/g-logo.png'} alt="google" />
          &nbsp;Continue with Google
        </button>
        <button
          type="button"
          className="signin-google"
          onClick={onSignUpWithFacebook}
        >
          <img
            src={process.env.PUBLIC_URL + '/facebook-logo.png'}
            alt="facebook"
          />
          &nbsp;Continue with Facebook
        </button>
        <span style={{ textAlign: 'center' }}>Or sign up with email</span>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your Email"
          onChange={onEmailChange}
        />
        <input
          id="password"
          type="password"
          name="password"
          placeholder="Password"
          onChange={onPasswordChange}
        />
        <button className="login-button" type="button" onClick={onSignup}>
          Sign up
        </button>
      </Fragment>
    )
  }

  return (
    <form>
      <h1>Sign up</h1>
      <p>
        Already have an account? <Link to="/signin">Log in instead</Link>
      </p>
      {renderFormSection()}
      <div className="accept-section">
        <p>
          By signing up, I agree to valuestocks.io&nbsp;
          <a href="/terms">terms of service</a> and acknowledge
          valuestocks.io&nbsp;
          <a href="/privacy">privacy policy</a>.
        </p>
        <hr />
        <Link to="/">Go Back</Link>
      </div>
    </form>
  )
}
