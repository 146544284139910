import { Doughnut } from 'react-chartjs-2'
import {
  blue,
  getTransparentColor,
  green,
  orange,
  red,
  yellow,
} from '../utils/Colors'
import { Chip, Grid, Stack, useMediaQuery } from '@mui/material'
import { padZeroToOne } from '../utils/Numbers'

const getColorFromValue = (value) => {
  if (value < 0.2) {
    return red
  }
  if (value < 0.4) {
    return orange
  }
  if (value < 0.6) {
    return yellow
  }
  if (value < 0.8) {
    return blue
  }
  return green
}

function GoodReason({ children }) {
  return (
    <div style={{ textAlign: 'left', display: 'flex' }}>
      <span class="material-icons" style={{ color: green }}>
        check_circle
      </span>
      {children}
    </div>
  )
}

function BadReason({ children }) {
  return (
    <div style={{ textAlign: 'left', display: 'flex' }}>
      <span class="material-icons" style={{ color: red }}>
        error
      </span>
      {children}
    </div>
  )
}

function NeutralReason({ children }) {
  return (
    <div style={{ textAlign: 'left', display: 'flex' }}>
      {/* <span class="material-icons" style={{ color: 'grey' }}>
        radio_button_unchecked
      </span> */}
      {children}
    </div>
  )
}

function ScoreDetailsSection({ children, percent, title, hideDetails }) {
  const lgMediaQuery = useMediaQuery('(min-width:1024px)')
  return (
    <Stack
      direction={lgMediaQuery ? 'row' : 'column'}
      alignItems="center"
      justifyContent={hideDetails ? 'center' : 'flex-start'}
    >
      <ScoreDetailDoughnut
        title={title}
        value={percent > 1 ? 1 : percent < 0 ? 0 : percent}
      />

      {!hideDetails && (
        <Stack
          alignItems="flex-start"
          justifyContent="center"
          style={{ padding: '0 1rem' }}
        >
          {children}
        </Stack>
      )}
    </Stack>
  )
}

export function ScoreDetailDoughnut({ title, value, inline = false }) {
  const color = getColorFromValue(value)
  const centerText = {
    id: 'centerText',
    afterDraw: function (chart, args, options) {
      let theCenterText = options.text
      const canvasBounds = chart.canvas.getBoundingClientRect()
      const fontSz = Math.floor(canvasBounds.height * 0.1)
      chart.ctx.textBaseline = 'middle'
      chart.ctx.textAlign = 'center'
      chart.ctx.font = fontSz + 'px Arial'
      chart.ctx.fillText(
        theCenterText,
        canvasBounds.width / 2,
        canvasBounds.height / 2 - fontSz
      )
      chart.ctx.restore()
    },
  }
  const bottomLabel = {
    id: 'bottomLabel',
    afterDraw: function (chart, args, options) {
      let theCenterText = options.text
      const canvasBounds = chart.canvas.getBoundingClientRect()
      const fontSz = Math.floor(canvasBounds.height * 0.1)
      chart.ctx.textBaseline = 'bottom'
      chart.ctx.textAlign = 'center'
      chart.ctx.font = fontSz + 'px Arial'
      chart.ctx.fillText(
        theCenterText,
        canvasBounds.width / 2,
        canvasBounds.height / 2 + fontSz
      )
      chart.ctx.restore()
    },
  }
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    animation: false,
    plugins: {
      tooltip: false,
      legend: {
        display: false,
        position: 'top',
      },
      centerText: {
        text: (value * 100).toFixed(0),
      },
      bottomLabel: {
        text: title,
      },
    },
  }

  const data = {
    labels: [title, ''],
    datasets: [
      {
        label: title,
        data: [value, 1 - value],
        backgroundColor: [
          getTransparentColor(color),
          getTransparentColor('#e3e3e3'),
        ],
        rotation: -180,
        borderWidth: 1,
        borderColor: [color, getTransparentColor('#fff')],
        hoverBackgroundColor: [color, getTransparentColor('#fff')],
      },
    ],
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Doughnut
        style={{
          maxWidth: inline ? '100px' : '125px',
          maxHeight: inline ? '100px' : '125px',
          minWidth: inline ? '70px' : '100px',
          minHeight: inline ? '70px' : '100px',
        }}
        options={options}
        data={data}
        plugins={[centerText, bottomLabel]}
      />
    </div>
  )
}

export function ScoreDetailSectionComponentInline({
  zscore,
  fscore,
  vscore,
  safety,
}) {
  return (
    <Grid container spacing="1rem">
      <Grid item xs={3}>
        <ScoreDetailDoughnut
          inline
          title="Price"
          value={padZeroToOne(safety / 0.35)}
        />
      </Grid>
      <Grid item xs={3}>
        <ScoreDetailDoughnut
          inline
          title="Growth"
          value={padZeroToOne(vscore.totalScore)}
        />
      </Grid>
      <Grid item xs={3}>
        <ScoreDetailDoughnut
          inline
          title="Finances"
          value={padZeroToOne(fscore.totalScore / 9)}
        />
      </Grid>
      <Grid item xs={3}>
        <ScoreDetailDoughnut
          inline
          title="Credit"
          value={padZeroToOne(zscore.score / 3)}
        />
      </Grid>
    </Grid>
  )
}

export function ScoreDetailSectionComponent({
  zscore,
  fscore,
  vscore,
  safety,
  fairValue = 'NA',
  price = 'NA',
  expanded = false,
}) {
  return (
    <Grid container padding="2rem" spacing="2rem">
      <Grid item xs={expanded ? 12 : 6} sm={expanded ? 12 : 3}>
        <ScoreDetailsSection
          title="Margin of safety"
          percent={safety}
          hideDetails={!expanded}
        >
          <NeutralReason>
            <Chip
              size="small"
              label={(Number(price) && price.toFixed(2)) ?? price}
            />
            = Current Price
          </NeutralReason>
          <NeutralReason>
            <Chip
              size="small"
              label={(Number(fairValue) && fairValue.toFixed(2)) ?? fairValue}
            />
            = Fair Value
          </NeutralReason>
          <NeutralReason>
            <Chip size="small" label={safety.toFixed(2)} />= Margin of safety
          </NeutralReason>
        </ScoreDetailsSection>
      </Grid>
      <Grid item xs={expanded ? 12 : 6} sm={expanded ? 12 : 3}>
        <ScoreDetailsSection
          title="Growth"
          percent={vscore.totalScore}
          hideDetails={!expanded}
        >
          <Grid container>
            {Object.keys(vscore.scores)
              .filter((k) => k !== 'totalScore')
              .map((k) => {
                const m = vscore.scores[k]
                return m.score > 0.05 ? (
                  <Grid item sm={12} md={6}>
                    <GoodReason>{m.description}</GoodReason>
                  </Grid>
                ) : (
                  <Grid item sm={12} md={6}>
                    <BadReason>{m.description}</BadReason>
                  </Grid>
                )
              })}
          </Grid>
        </ScoreDetailsSection>
      </Grid>
      <Grid item xs={expanded ? 12 : 6} sm={expanded ? 12 : 3}>
        <ScoreDetailsSection
          title="Finances"
          percent={fscore.totalScore / 9}
          hideDetails={!expanded}
        >
          <Grid container>
            {Object.keys(fscore)
              .filter((k) => k !== 'totalScore')
              .sort((k) => fscore[k].score > 0)
              .map((k) => {
                const m = fscore[k]
                return m.score > 0 ? (
                  <Grid item sm={12} md={6}>
                    <GoodReason>{m.description}</GoodReason>
                  </Grid>
                ) : (
                  <Grid item sm={12} md={6}>
                    <BadReason>{m.description}</BadReason>
                  </Grid>
                )
              })}
          </Grid>
          <NeutralReason>
            <Chip size="small" label={fscore.totalScore.toFixed(2)} /> =
            Piotroski F-score
          </NeutralReason>
          <small style={{ textAlign: 'left' }}>
            * A company has a score of 8 or 9, it is considered a good value. If
            the score adds up to between 0-2 points, the stock is considered
            weak.
          </small>
        </ScoreDetailsSection>
      </Grid>
      <Grid item xs={expanded ? 12 : 6} sm={expanded ? 12 : 3}>
        <ScoreDetailsSection
          title="Credit"
          percent={zscore.score / 3}
          hideDetails={!expanded}
        >
          <NeutralReason>
            <Chip size="small" label={zscore.x1.toFixed(2)} /> = Working Capital
            / Total assets
          </NeutralReason>
          <NeutralReason>
            <Chip size="small" label={zscore.x2.toFixed(2)} /> = Retained
            Earnings / Total assets
          </NeutralReason>
          <NeutralReason>
            <Chip size="small" label={zscore.x3.toFixed(2)} /> = EBIT / Total
            assets
          </NeutralReason>
          <NeutralReason>
            <Chip size="small" label={zscore.x4.toFixed(2)} /> = Market Value of
            Equity / Total liabilities
          </NeutralReason>
          <NeutralReason>
            <Chip size="small" label={zscore.x5.toFixed(2)} /> = Sales / Total
            assets
          </NeutralReason>
          <NeutralReason>
            <Chip size="small" label={zscore.score.toFixed(2)} /> = Altman
            Z-Score
          </NeutralReason>
          <small style={{ textAlign: 'left' }}>
            * A score below 1.8 means it's likely the company is headed for
            bankruptcy, while companies with scores above 3 are not likely to go
            bankrupt
          </small>
        </ScoreDetailsSection>
      </Grid>
    </Grid>
  )
}
