import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: '' }
  }

  componentDidCatch(error) {
    this.setState({ error: `${error.name}: ${error.message}` })
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div className="not-found">
          <h1>😕 Oops! Something went wrong.</h1>
          <a className="primary-button" href="/">
            Go Back
          </a>
        </div>
      )
    } else {
      return <>{this.props.children}</>
    }
  }
}
