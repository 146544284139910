export const Oops = ({ symbol }) => {
  return (
    <div className="not-found">
      <h1>😕 Oops! {symbol} not found.</h1>
      <a className="primary-button" href="/">
        Go Back
      </a>
    </div>
  )
}
