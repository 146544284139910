import { useState } from 'react'
import { useActionData, useNavigate } from 'react-router-dom'
import Toggle from '../components/Toggle'
import { useAuthContext } from '../contexts/AuthContext'
import { ApiClient } from '../network/ApiClient'
import { Payment } from './Payment'
import './Plans.css'
import * as tracking from '../services/tracking'

export default function PlanSelectPayment() {
  const [plan, setPlan] = useState('annual')
  const [error, setError] = useState({ show: false, message: '' })
  const navigate = useNavigate()
  const authContext = useAuthContext()

  const handlePlanChange = () => {
    if (plan === 'annual') {
      setPlan('monthly')
    } else {
      setPlan('annual')
    }
  }

  const handleOnApprove = async (subscriptionID) => {
    try {
      const apiClient = new ApiClient()
      setError({ show: false, message: '' })
      await apiClient.createNewSubscription(subscriptionID)
      const account = await apiClient.getAccountDetails()
      authContext.setAccount(account)
      tracking.setPremium()
      navigate('/premium')
    } catch (err) {
      setError({
        show: true,
        message: 'Failed to process payment. Make sure you are logged in.',
      })
    }
  }

  const handleOnError = (error) => {
    console.error(error)
    setError({
      show: true,
      message: 'Failed to process payment. Make sure you are logged in.',
    })
  }

  const handleCancel = () => {
    navigate('/account')
  }

  return (
    <div>
      {/* <div className="pricing-card"> */}
      {/* <h2>Premium</h2> */}
      {/* <hr /> */}

      <div className="pricing-card-price-details">
        {plan === 'annual' && <h2>$8.25/month</h2>}
        {plan === 'annual' && <p>billed at 99$/yr</p>}
        {plan === 'monthly' && <h2>$12/month</h2>}
        {plan === 'monthly' && <p>billed monthly</p>}
      </div>
      {error.show && (
        <p
          style={{
            color: 'white',
            backgroundColor: 'darkred',
            padding: '1rem',
          }}
        >
          {error.message}
        </p>
      )}

      <Toggle
        checkedLabel="Monthly"
        uncheckedLabel="Annual"
        onChange={handlePlanChange}
        checked={plan === 'monthly'}
      />
      {plan === 'monthly' && (
        <Payment
          onCancel={handleCancel}
          onApprove={handleOnApprove}
          onError={handleOnError}
          planId={
            process.env.NODE_ENV === 'production'
              ? process.env.REACT_APP_PROD_MONTHLY_PLAN_ID
              : process.env.REACT_APP_DEV_MONTHLY_PLAN_ID
          }
        />
      )}
      {plan === 'annual' && (
        <Payment
          onCancel={handleCancel}
          onApprove={handleOnApprove}
          onError={handleOnError}
          planId={
            process.env.NODE_ENV === 'production'
              ? process.env.REACT_APP_PROD_YEARLY_PLAN_ID
              : process.env.REACT_APP_DEV_YEARLY_PLAN_ID
          }
        />
      )}
    </div>
  )
}
