import React, { useContext } from 'react'

export const AppContext = React.createContext({
  lastUpdate: null,
  stockData: null,
  valuations: null,
  industriesAverages: { data: [], loading: false },
  symbol: null,
  location: null,
  onLoading: null,
  query: null,
  queryResults: null,
  failedToFetch: null,
  setStockData: () => {},
  setSymbol: () => {},
  setLocation: () => {},
  onPriceChanged: () => {},
  onStockChanged: () => {},
  onCompetitorsChanged: () => {},
  fetchStockData: () => {},
  setQuery: () => {},
  setQueryResults: () => {},
  getAveragesByIndustry: (industry) => {},
})

export function useAppContext() {
  return useContext(AppContext)
}
