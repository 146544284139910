const colors = [
  '#4e79a7',
  '#f28e2c',
  '#e15759',
  '#76b7b2',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ab',
]

export const blue = colors[0]
export const orange = colors[1]
export const red = colors[2]
export const teal = colors[3]
// export const green = colors[4]
export const yellow = colors[5]
export const majenta = colors[6]
export const fillColor = 'rgba(33,150,243,0.2)'

export const getTransparentColor = (color) => hexToRgbA(color)

export const green1 = '#D8FFF1'
export const green2 = '#92FFD5'
export const green3 = '#48EDB2'
export const green4 = '#0BCD8B'
export const green5 = '#13A77D'
export const green6 = '#1A6357'
export const green = green5

function hexToRgbA(hex) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.45)'
    )
  }
  throw new Error('Bad Hex')
}
