import { Fragment, useEffect, useState } from 'react'
import { signIn, signInWithFacebook, signInWithGoogle } from '../services/auth'
import { useAuthContext } from '../contexts/AuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import './SignUpPage.css'
import { LoginForm } from '../components/LoginForm'
import * as tracking from '../services/tracking'

const LoginPage = () => {
  const search = useLocation().search
  const utmSource = new URLSearchParams(search).get('utm_source')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showSignUp, setShowSignUp] = useState(
    utmSource === 'blog' || utmSource === 'site' || false
  )
  const [showLoginError, setShowLoginError] = useState(false)
  const [showSignupError, setShowSignupError] = useState(false)
  const authContext = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (authContext.signedIn) {
      navigate('/premium')
    }
  }, [authContext.signedIn, authContext.account])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleLogin = async (e) => {
    try {
      e.preventDefault()
      const uid = await signIn({ email, password })
      tracking.login('email', uid)
    } catch (err) {
      setShowLoginError(true)
    }
  }

  const handleLoginWithGoogle = async () => {
    try {
      setShowLoginError(false)
      const uid = await signInWithGoogle()
      tracking.login('google', uid)
    } catch (err) {
      setShowLoginError(true)
    }
  }

  const handleFacebookLogin = async () => {
    try {
      setShowLoginError(false)
      const uid = await signInWithFacebook()
      tracking.login('facebook', uid)
    } catch (err) {
      setShowLoginError(true)
    }
  }

  return (
    <Fragment>
      <div className="login-wrapper">
        <div className="login-form">
          <LoginForm
            onEmailChange={handleEmailChange}
            onPasswordChange={handlePasswordChange}
            onLogin={handleLogin}
            onLoginWithGoogle={handleLoginWithGoogle}
            onLoginWithFacebook={handleFacebookLogin}
            onClose={(e) => {
              e.preventDefault()
              // setShowSignUp(false)
            }}
            email={email}
            password={password}
            showSignupError={showSignupError}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default LoginPage
