import mixpanel from 'mixpanel-browser'

export const Events = {
  Signup: 'signup',
  Login: 'login',
  Visit: 'visit',
  Subscribe: 'subscribe',
  Cancel: 'cancel',
  Bookmark: 'bookmark',
  Search: 'search',
  Analyze: 'analyze',
  Navigate: 'navigate',
}

export const Properties = {
  LoginCount: 'login_count',
  SearchTerm: 'search_term',
  SubscriptionPlan: 'subscription_plan',
  Ticker: 'ticker',
  SP500: 'sp500',
  SPTSX: 'sptsx',
  RUSSELL2000: 'russell2000',
  CHINA: 'china',
}

mixpanel.init('5c95844bc6bdece2ed3edb1e16ff5965', {
  api_host:
    process.env.NODE_ENV === 'production'
      ? 'https://proxy.valuestocks.io'
      : 'localhost:3003',
  debug: process.env.NODE_ENV !== 'production',
})

export const signup = (source, uid) => {
  mixpanel.identify(uid)
  mixpanel.track(Events.Signup, { source, user_id: uid })
}

export const login = (source, uid) => {
  mixpanel.identify(uid)
  mixpanel.track(Events.Login, { source, user_id: uid })
  mixpanel.people.increment(Properties.LoginCount)
}

export const search = (term) =>
  mixpanel.track(Events.Search, { [Properties.SearchTerm]: term })

export const setPremium = () =>
  mixpanel.people.set({ [Properties.SubscriptionPlan]: 'premium' })

export const analyze = (ticker) =>
  mixpanel.track(Events.Analyze, { [Properties.Ticker]: ticker.toUpperCase() })

export const navigate = (index) => mixpanel.track(Events.Navigate, { index })

export const bookmark = (ticker) =>
  mixpanel.track(Events.Bookmark, { [Properties.Ticker]: ticker.toUpperCase() })
