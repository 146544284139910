import { AppBar, IconButton, Toolbar } from '@mui/material'
import { useState } from 'react'
import TemporaryDrawer from './Drawer'
import { useAuthContext } from '../contexts/AuthContext'

const Header = ({ children, lg }) => {
  const [open, setOpen] = useState(false)
  const authContext = useAuthContext()

  if (lg) {
    return (
      <header>
        <div className="content">{children}</div>
      </header>
    )
  }

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        {authContext.signedIn && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => setOpen(!open)}
          >
            <span className="material-icons">menu</span>
          </IconButton>
        )}
        {children}
      </Toolbar>
      <TemporaryDrawer isOpen={open} handleToggleDrawer={setOpen} />
    </AppBar>
  )
}

export default Header
