import './Toggle.css'

const Toggle = ({
  uncheckedLabel = 'unchecked',
  checkedLabel = 'checked',
  onChange,
  disable: disabled = false,
  title,
  checked,
}) => {
  return (
    <div className="toggle" title={title}>
      <label className={!disabled ? `state-label` : `state-label dark`}>
        {uncheckedLabel}
      </label>
      <label className="switch">
        <input
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          checked={checked}
        />
        <span className="slider round"></span>
      </label>
      <label className={!disabled ? `state-label` : `state-label dark`}>
        {checkedLabel}
      </label>
    </div>
  )
}

export default Toggle
