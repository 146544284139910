import { Fragment } from 'react'
import './Terms.css'

export const Terms = () => {
  window.scrollTo({ top: 0 })
  return (
    <Fragment>
      <div className="terms-section">
        <h2>Terms of Service</h2>
        <p>
          When we say ”company”, “we”, “our”, “us”, ”service” or ”services” in
          this document, we are referring to ValueStocks.
        </p>
        <p>
          We may update these Terms of Service in the future. Whenever we make a
          significant change to our policies, we will also announce them by
          email.
        </p>
        <p>
          When you use our service, now or in the future, you are agreeing to
          the latest Terms of Service. That’s true for any of our existing and
          future products and all features that we add to our service over time.
          There may be times where we do not exercise or enforce any right or
          provision of the Terms of Service; in doing so, we are not waiving
          that right or provision. These terms do contain a limitation of our
          liability.
        </p>
        <p>
          If you do not agree to these Terms of Service, do not use this
          service. Violation of any of the terms below may result in the
          termination of your account.
        </p>
        <h3>Account terms </h3>
        <p>
          You are responsible for maintaining the security of your account and
          password. ValueStocks cannot and will not be liable for any loss or
          damage from your failure to comply with this security obligation.
        </p>
        <p>
          You are responsible for any activity that occurs under your account.
        </p>
        <p>
          You may not use our service for any illegal purpose or to violate any
          laws in your jurisdiction.
        </p>
        <p>
          You must provide a valid email address in order to complete the signup
          process.
        </p>
        <h3>Payment, refunds terms</h3>
        <p>
          For our free trial, we explain the length of trial when you sign up.
          We do not ask you for your credit card and — just like for customers
          who pay for our services — we do not store, sell or distribute your
          data. After the trial period, you need to pay in advance to keep using
          the service. If you do not pay, we will freeze your account and it
          will be inaccessible until you make payment. If your account has been
          frozen for 60 days, we will queue it up for auto-cancellation.
        </p>
        <p>
          If you are upgrading from a free trial to a paid plan, we will charge
          you immediately and your billing cycle starts on the day of upgrade.
        </p>
        <p>Paying customers are billed automatically via credit card.</p>
        <p>
          There are no surprise fees and your card will never be charged
          unexpectedly.
        </p>
        <p>
          Our payment process is conducted by our payment processor Paypal.
          Paypal provides customer service inquiries and handles returns. All
          fees are inclusive of all taxes, levies, or duties imposed by taxing
          authorities. Paypal will collect those taxes on behalf of taxing
          authority and remit those taxes to taxing authorities. See Paypal’s
          Terms of Use for details.
        </p>
        <p>Fees paid hereunder are non-refundable.</p>
        <h3>Cancellation and termination</h3>
        <p>
          You are solely responsible for properly canceling your account. An
          email to cancel your account is not considered cancellation. You can
          cancel your account by login into your paypal account clicking on
          Setttings, Payments, Manage automatic payments. Look for Prakto, Inc.
          and hit "Cancel". You can also follow the detailed instructions on the
          official
          <a href="https://www.paypal.com/us/smarthelp/article/how-to-cancel-a-subscription-or-other-automatic-payments-faq2254">
            Paypal website
          </a>
          .
        </p>
        <p>
          If you cancel the service before the end of your current paid up
          period, your cancellation will take effect at the end of the current
          billing cycle, and you will not be charged again. All future
          transactions will not be accessible after the time you paid for
          expires.
        </p>
        <p>
          We reserve the right to suspend or terminate your account and refuse
          any and all current or future use of the service for any reason at any
          time. Such termination of the service will result in the deactivation
          or deletion of your account or your access to your account.
          ValueStocks reserves the right to refuse service to anyone for any
          reason at any time. We have this clause because there may be
          individuals doing something nefarious.
        </p>
        <p>
          Verbal, physical, written or other abuse (including threats of abuse
          or retribution) of any service customer, company employee or officer
          may result in immediate account termination.
        </p>
        <h3>Modifications to the service and prices</h3>
        <p>
          We reserve the right at any time and from time to time to modify or
          discontinue, temporarily or permanently, any part of the service with
          or without notice.
        </p>
        <p>
          Sometimes we change the pricing structure for our products. When we do
          that, we tend to exempt existing customers from those changes.
          However, we may choose to change the prices for existing customers. If
          we do so, we will give at least 30 days notice and will notify you via
          the email address on record.
        </p>
        <p>
          ValueStocks shall not be liable to you or to any third-party for any
          modification, price change, suspension or discontinuance of the
          service.
        </p>
        <p></p>
        <h3> Content ownership, copyright and trademark</h3>
        <p>
          You may provide us with feedback, suggestions, and ideas about the
          service. You agree that we own all rights to use and incorporate the
          feedback you provide in any way, including in future enhancements and
          modifications to the service, without payment or attribution to you.
        </p>
        <p>
          You must not modify another website so as to falsely imply that it is
          associated with ValueStocks. The look and feel of the service is
          copyright© to ValueStocks. All rights reserved. “ValueStocks”, the
          ValueStocks logo and any other product or service name or slogan
          displayed on the service are trademarks of the company and may not be
          copied, imitated or used, in whole or in part, without the prior
          written permission of the company or the applicable trademark holder.
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the service, use of the service, or access to the
          service without the express written permission by the company.
        </p>
        <h3>Privacy and security of your data</h3>
        <p>
          You agree that ValueStocks may process your data as described in our
          data policy and for no other purpose.
        </p>
        <p>
          Each party agrees to handle the other party’s data in accordance with
          (i) all applicable laws; and (ii) privacy and security measures
          reasonably adequate to preserve the other party data’s confidentiality
          and security.
        </p>
        <p>
          You own all right, title, and interest to your data. We obtain no
          rights from you to your data. We do not collect and analyze personal
          information from web users and we do not use behavioral insights to
          sell advertisements. We will never collect or store any personally
          identifiable information and we will never abuse your privacy. When
          using ValueStocks, you 100% own and control all of your data. We will
          never sell or share your data to any third-parties.
        </p>
        <p>
          You agree to comply with all applicable laws including all privacy and
          data protection regulations.
        </p>
        <p>
          You agree not to use the service to send sensitive information to the
          company where unauthorized disclosure could cause material, severe, or
          catastrophic harm or impact to the company, any data subjects or
          third-parties. Sensitive information includes, but is not limited to
          credit card information, passport numbers, government issued
          identification numbers, financial account information, real time
          geolocation and personally identifiable information (PII). PII is
          information that could be used on its own to directly identify,
          contact, or precisely locate an individual.
        </p>
        <h3>General conditions</h3>
        <p>
          Your use of ValueStocks is at your sole risk. The service is provided
          on an “as is” and “as available” basis. We do take uptime of our
          application seriously.
        </p>
        <p>
          We design our services with care, based on our own experience and the
          experiences of customers who share their time and feedback. However,
          there is no such thing as a service that pleases everybody. We make no
          guarantees that our services will meet your specific requirements or
          expectations.
        </p>
        <p>
          We also test all of our features extensively before shipping them. As
          with any software, our services inevitably have some bugs. We track
          the bugs reported to us and work through priority ones, especially any
          related to security or privacy. Not all reported bugs will get fixed
          and we don’t guarantee completely error-free services.
        </p>
        <p>
          Technical support is provided by email. Email responses are provided
          on the reasonable effort basis without guaranteed response time.
        </p>
        <p>
          We as humans cannot access your data to help you with support requests
          you make.
        </p>
        <p>
          We use third party vendors to provide the necessary hardware, storage,
          payment processing and related technology required to run the
          Services. You can see a list of all subprocessors here.
        </p>
        <h3>Liability</h3>
        <p>
          We mention liability throughout these Terms but to put it all in one
          section:
        </p>
        <p>
          You expressly understand and agree that ValueStocks shall not be
          liable, in law or in equity, to you or to any third party for any
          direct, indirect, incidental, lost profits, special, consequential,
          punitive or exemplary damages, including, but not limited to, damages
          for loss of profits, goodwill, use, data or other intangible losses
          (even if the company has been advised of the possibility of such
          damages), resulting from: (i) the use or the inability to use the
          services; (ii) the cost of procurement of substitute goods and
          services resulting from any goods, data, information or services
          purchased or obtained or messages received or transactions entered
          into through or from the services; (iii) unauthorized access to or
          alteration of your transmissions or data; (iv) statements or conduct
          of any third party on the service; (v) or any other matter relating to
          this Terms of Service or the services, whether as a breach of
          contract, tort (including negligence whether active or passive), or
          any other theory of liability.
        </p>
        <p>
          In other words: choosing to use our services does mean you are making
          a bet on us. If the bet does not work out, that’s on you, not us. We
          do our best to be as safe a bet as possible. If you choose to use our
          services, thank you for betting on us.
        </p>
        <p>
          This agreement shall be governed by the laws of Canada, and the courts
          of Canada shall have exclusive jurisdiction to hear and determine all
          issues that may arise under or in relation to this agreement.
        </p>
        <h3>Contact us</h3>
        <p>
          If you have a question about any of the Terms of Service, please&nbsp;
          <a href="mailto:contact@valuestocks.io">contact us</a>.
        </p>
        <p>Last updated: April 26, 2022</p>
      </div>
    </Fragment>
  )
}
