export const STOCKS_DATA_KEY = 'vlustx-stocks'
export const QUOTES_DATA_KEY = 'vlustx-quotes'
export const ACCOUNT_DETAILS_DATA_KEY = 'vlustx-account-details'
export const VOTE_KEY = 'vlustx-vote'

export const setCustomerId = (customerId) =>
  localStorage.setItem('vlustx-id', customerId)

export const getCustomerId = () => localStorage.getItem('vlustx-id')

export const removeCustomerId = () => localStorage.removeItem('vlustx-id')

export const cacheData = (key, value, storageKey) => {
  const stringData = localStorage.getItem(storageKey)
  if (!stringData || stringData === '') {
    localStorage.setItem(
      storageKey,
      JSON.stringify({ [key]: { ...value, ts: new Date() } })
    )
  } else {
    const jsonData = JSON.parse(stringData)
    jsonData[key] = value
    localStorage.setItem(
      storageKey,
      JSON.stringify({ [key]: { ...value, ts: new Date() } })
    )
  }
}

export const getCache = (key, storageKey) => {
  try {
    const stringData = localStorage.getItem(storageKey)
    const jsonData = JSON.parse(stringData)
    return jsonData[key]
  } catch (er) {
    return undefined
  }
}

export const clearCache = (key) => localStorage.removeItem(key)
