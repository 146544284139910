import { Fragment, useEffect, useState } from 'react'
import { SignupForm } from '../components/SignupForm'
import {
  registerWithEmailAndPassword,
  signInWithFacebook,
  signInWithGoogle,
} from '../services/auth'
import { useAuthContext } from '../contexts/AuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import './SignUpPage.css'
import * as tracking from '../services/tracking'

const SignUpPage = () => {
  const search = useLocation().search
  const plan = new URLSearchParams(search).get('plan')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showLoginError, setShowLoginError] = useState(false)
  const [showSignupError, setShowSignupError] = useState(false)
  const authContext = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (authContext.signedIn) {
      navigate('/premium')
    }
  }, [authContext.signedIn, authContext.account])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSignUpWithEmail = async () => {
    try {
      setShowSignupError(false)
      const uid = await registerWithEmailAndPassword({ email, password })
      tracking.signup('email', uid)
    } catch (err) {
      setShowSignupError(true)
    }
  }

  const handleSignUpWithGoogle = async () => {
    try {
      setShowSignupError(false)
      const uid = await signInWithGoogle()
      tracking.signup('google', uid)
    } catch (err) {
      setShowSignupError(true)
    }
  }

  const handleSignUpWithFacebook = async () => {
    try {
      setShowSignupError(false)
      const uid = await signInWithFacebook()
      tracking.signup('facebook', uid)
    } catch (err) {
      setShowSignupError(true)
    }
  }

  return (
    <Fragment>
      <div className="login-wrapper">
        <div className="login-form">
          <SignupForm
            onEmailChange={handleEmailChange}
            onPasswordChange={handlePasswordChange}
            onSignup={handleSignUpWithEmail}
            onSignUpWithGoogle={handleSignUpWithGoogle}
            onSignUpWithFacebook={handleSignUpWithFacebook}
            onClose={(e) => {
              e.preventDefault()
              // setShowSignUp(false)
            }}
            email={email}
            password={password}
            showSignupError={showSignupError}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default SignUpPage
