import { Card } from '@mui/material'
import '../App.css'

export const BigNumber = ({ label, value, title, disabled = false }) => {
  return (
    <Card
      className={!disabled ? 'big-number' : 'big-number disabled'}
      title={title}
      variant="outlined"
    >
      <label>{label}</label>
      <span>{value}</span>
    </Card>
  )
}
