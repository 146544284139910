import { Fragment } from 'react'
import Explain from './Explain'

export default function CreditTitle() {
  return (
    <div style={{ display: 'inline-flex' }}>
      Credit Score&nbsp;
      <Explain
        title="Credit Score"
        description={
          <Fragment>
            <span>
              The Credit Score utilizes the Altman Z-Score to assess the
              creditworthiness of a company. The Altman Z-Score is a well-known
              financial metric developed by Professor Edward I. Altman, which is
              widely used to predict the likelihood of corporate bankruptcy.
            </span>
            <br />
            <br />
            <span>
              The Credit Score is calculated on a scale of 0 to 100, with a
              score of 3 representing a perfect score of 100/100 points. A score
              of 3 indicates a very low risk of bankruptcy, suggesting a high
              level of creditworthiness for the assessed company.
            </span>
            <br />
            <br />
            <span>
              The Altman Z-Score takes into consideration several financial
              ratios and other financial indicators, including profitability,
              liquidity, solvency, efficiency, and market value measures. These
              ratios are analyzed and combined using a predetermined formula to
              calculate the Altman Z-Score, which is then mapped to the Credit
              Score scale for ease of interpretation.
            </span>
            <br />
            <br />
            <span>
              A Credit Score of 100/100 (3) would suggest that the assessed
              company has a very low risk of bankruptcy, indicating a strong
              financial position and a high level of creditworthiness. This
              could imply that the company is financially stable and may be
              considered low-risk for potential creditors or investors.
            </span>
            <br />
            <br />
            <span>
              On the other hand, a lower Credit Score below 100/100 (3) would
              suggest a higher risk of bankruptcy, indicating a weaker financial
              position and a lower level of creditworthiness. This may raise
              concerns for potential creditors or investors, and further
              analysis may be needed before making credit or investment
              decisions.
            </span>
            <br />
            <br />
            <span>
              The Credit Score feature provides users with an effective tool to
              assess the creditworthiness of a company using the Altman Z-Score,
              helping them make informed decisions when evaluating credit risk
              or investment opportunities.
            </span>
          </Fragment>
        }
      />
    </div>
  )
}
