import { TableCell, TableRow } from '@mui/material'
import CreditTitle from './CreditTitle'
import FinancesTitle from './FinancesTitle'
import GrowthTitle from './GrowthTitle'
import { MarginOfSafetyTitle } from './PriceTitle'
import { TotalScoreTitle } from './TotalScoreTitle'

export default function StockItemHeader({ lg, md }) {
  if (lg) {
    return (
      <TableRow>
        <TableCell style={{ textAlign: 'left' }}>Ticker</TableCell>

        <TableCell style={{ textAlign: 'left' }}>Company</TableCell>
        <TableCell style={{ textAlign: 'left' }}>Industry</TableCell>
        <TableCell style={{ textAlign: 'left' }}>
          <TotalScoreTitle />
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <MarginOfSafetyTitle />
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <GrowthTitle />
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <FinancesTitle />
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <CreditTitle />
        </TableCell>
      </TableRow>
    )
  }

  // Dont render header for mobile
  return null
}
