import React, { useContext } from 'react'

export const AuthContext = React.createContext({
  userId: null,
  signedIn: null,
  setSignedIn: (data) => {},
  token: null,
  setToken: (data) => {},
  data: null,
  setData: (data) => {},
  forbidden: null,
  setForbidden: (data) => {},
  bookmarks: null,
  setBookmarks: (data) => {},
  addBookmarks: (data) => {},
  removeBookmark: (data) => {},
  account: null,
  setAccount: (data) => {},
})

export function useAuthContext() {
  return useContext(AuthContext)
}
