import { CircularProgress } from '@mui/material'

const BookmarkWidget = ({
  symbol,
  location,
  bookmarks,
  onSave,
  onDelete,
  loading,
}) => {
  const ticker = `${symbol}:${location}`

  if (loading) {
    return <CircularProgress />
  }

  if (!bookmarks || bookmarks.filter((b) => b.ticker === ticker).length === 0) {
    return (
      <i className="bookmark" title="Bookmark" onClick={() => onSave(symbol)}>
        <span className="material-icons">bookmark_outline</span>
      </i>
    )
  }

  return (
    <i className="bookmark" title="Bookmark" onClick={() => onDelete(symbol)}>
      <span className="material-icons">bookmark</span>
    </i>
  )
}

export default BookmarkWidget
