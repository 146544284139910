import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import checkMarkSVG from './Checkmark'

export const LoginForm = ({
  onEmailChange,
  onPasswordChange,
  onClose,
  onLogin,
  onLoginWithGoogle,
  onLoginWithFacebook,
}) => {
  const authContext = useAuthContext()

  const renderFormSection = () => {
    return authContext.signedIn ? (
      <div>{checkMarkSVG}</div>
    ) : (
      <Fragment>
        <button
          type="button"
          className="signin-google"
          onClick={onLoginWithGoogle}
        >
          <img src={process.env.PUBLIC_URL + '/g-logo.png'} alt="graphs" />
          &nbsp;Continue with Google
        </button>
        <button
          type="button"
          className="signin-google"
          onClick={onLoginWithFacebook}
        >
          <img
            src={process.env.PUBLIC_URL + '/facebook-logo.png'}
            alt="facebook"
          />
          &nbsp;Continue with Facebook
        </button>
        <span style={{ textAlign: 'center' }}>Or log in with email</span>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your Email"
          onChange={onEmailChange}
        />
        <input
          id="password"
          type="password"
          name="password"
          placeholder="Password"
          onChange={onPasswordChange}
        />
        <button className="login-button" type="button" onClick={onLogin}>
          Log in
        </button>
      </Fragment>
    )
  }

  return (
    <form>
      <h1>Log in</h1>
      <p>
        Don't have an account? <Link to="/signup">Create one now</Link>
      </p>
      {renderFormSection()}
      <p style={{ textAlign: 'center' }}>
        <Link to="/forgot-password">Forgot your password?</Link>
      </p>
      <div className="accept-section">
        <hr />
        <Link to="/">Go Back</Link>
      </div>
    </form>
  )
}
