import { Fragment, useEffect } from 'react'
import { StockItem } from '../components/StockItem'
import { ApiClient } from '../network/ApiClient'
import { useAuthContext } from '../contexts/AuthContext'
import SubscriptionGuard from '../components/SubscriptionGuard'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  useMediaQuery,
} from '@mui/material'
import StockItemHeader from '../components/StockItemHeader'

const Dashboard = () => {
  const authContext = useAuthContext()
  const lgMediaQuery = useMediaQuery('(min-width:1024px)')
  window.scrollTo({ top: 0 })

  useEffect(() => {
    const apiClient = new ApiClient()
    apiClient.getBookmarks(authContext.userId).then((data) => {
      authContext.setBookmarks(data.bookmarks)
    })
  }, [])

  const renderBookmarks = () => {
    if (!lgMediaQuery) {
      return (
        <div>
          {authContext.bookmarks &&
            authContext.bookmarks.length > 0 &&
            authContext.bookmarks.map((stock, index) => (
              <StockItem stock={stock} lg={lgMediaQuery} key={index} />
            ))}
        </div>
      )
    }

    return (
      <div>
        <Paper className="paper-div" variant="outline">
          <TableContainer>
            <Table>
              <TableHead>
                <StockItemHeader lg={lgMediaQuery} />
              </TableHead>
              <TableBody>
                {authContext.bookmarks &&
                  authContext.bookmarks.length > 0 &&
                  authContext.bookmarks.map((stock, index) => (
                    <StockItem stock={stock} lg={lgMediaQuery} key={index} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    )
  }

  const renderEmptyBookmarks = () => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          marginTop: '2rem',
          borderRadius: '8px',
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          You don't have any bookmarks yet!
        </h2>
        <img
          style={{ maxWidth: '100%', height: 'auto' }}
          src={process.env.PUBLIC_URL + '/valuestocks-bookmarks.gif'}
          alt="bookmarks-demo"
        />
      </div>
    )
  }

  return (
    <Fragment>
      <div style={{ minHeight: '100vh', maxWidth: '1000px', margin: '0 auto' }}>
        {authContext.bookmarks && authContext.bookmarks.length > 0
          ? renderBookmarks()
          : renderEmptyBookmarks()}
      </div>
    </Fragment>
  )
}

export default Dashboard
