import axios from 'axios'
import { getCookie } from '../utils/Cookies'

const baseUrl =
  process.env.NODE_ENV === 'production' ? 'https://api.valuestocks.io/' : '/'

const v1 = 'api/v1'
const v2 = 'api/v2'

export class ApiClient {
  constructor() {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        'X-VALUESTOCKS-API-KEY': 'K9NJFB8ouekVbgnfQd8xt',
        Authentication: `Bearer ${getCookie('auth-token')}`,
      },
    })
  }

  async createPaymentSession(email, password) {
    const { data } = await this.client.post(v1 + '/payment/session', {
      email,
      password,
    })
    return data
  }

  async createNewSubscription(subscriptionId) {
    const { data } = await this.client.post(v1 + '/subscription', {
      subscriptionId,
    })
    return data
  }

  async getAccountDetails() {
    const { data } = await this.client.get(v1 + '/account')
    return data
  }

  async cancelSub(customerId) {
    await this.client.post(v1 + `/account/${customerId}/cancel`)
  }

  async searchStock(query) {
    const { data } = await this.client.get(v1 + `/search?query=${query}`)
    return data
  }

  async voteForValuation(symbol, location, answer) {
    await this.client.post(v1 + `/vote/${symbol}/${location}`, { answer })
  }

  async getVoteRatio(symbol, location) {
    const { data } = await this.client.get(v1 + `/vote/${symbol}/${location}`)
    return data
  }

  async getStock(symbol, location, hasPremiumAccess) {
    // if (hasPremiumAccess) {
    return this.getPremiumStock(symbol, location)
    // } else {
    // const { data } = await this.client.get(
    // `/stocks/${symbol}/${location}/all`
    // )
    // return data
    // }
  }

  async getPremiumStock(symbol, location) {
    const { data } = await this.client.get(
      v1 + `/premium/stocks/${symbol}/${location}/all`
    )
    return data
  }

  async getIndustriesAverage(index) {
    const { data } = await this.client.get(
      v1 + `/premium/industries-avg/${index ?? ''}`
    )
    return data
  }

  async getCompetitors(symbol, location) {
    const { data } = await this.client.get(
      v1 + `/premium/competitors/${symbol}/${location}`
    )
    return data
  }

  async getQuote(symbol, location) {
    const { data } = await this.client.get(
      v2 + `/premium/stocks/${symbol}/${location}/quote`
    )
    return data
  }

  async getPriceHistory(symbol, location) {
    const { data } = await this.client.get(
      v1 + `/stocks/${symbol}/${location}/price-history`
    )
    return data
  }

  async getScreenerResults(indexName, skip, limit, params) {
    const { data } = await this.client.post(
      v1 + `/premium/screener/${indexName}?skip=${skip}&limit=${limit}`,
      { params }
    )
    return data
  }

  async addBookmark(ticker) {
    await this.client.post(v1 + `/bookmarks`, { ticker: ticker })
  }

  async removeBookmark(symbol, location) {
    await this.client.delete(v1 + `/bookmarks/${symbol}/${location}`)
  }

  async getBookmarks() {
    const { data } = await this.client.get(v1 + `/bookmarks`)
    return data
  }

  async getTrendingStocks() {
    const { data } = await this.client.get(v1 + `/trending`)
    return data
  }

  async getValuations(symbol, location) {
    const { data } = await this.client.get(
      v2 + `/premium/stocks/${symbol}/${location}/valuations`
    )
    return data
  }
}
