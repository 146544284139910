import './Account.css'
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import { Loading } from '../components/Loading'
import { ApiClient } from '../network/ApiClient'
import PlanSelectPayment from './PlanSelectPayment'
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Stack,
} from '@mui/material'
import { logout } from '../services/auth'

const Account = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({
    showError: false,
    message: undefined,
  })
  const authContext = useAuthContext()

  useEffect(() => {
    window.scrollTo({ top: 0 })
    const apiClient = new ApiClient()
    apiClient
      .getAccountDetails()
      .then((account) => {
        authContext.setAccount(account)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  if (!authContext) {
    return null
  }

  const handleCancelSub = async (e) => {
    try {
      setLoading(true)
      e.preventDefault()
      setError({ showError: false, message: '' })
      const apiClient = new ApiClient()
      await apiClient.cancelSub(authContext.userId)
      apiClient
        .getAccountDetails()
        .then((account) => {
          authContext.setAccount(account)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } catch (err) {
      setLoading(false)
      setError({
        showError: true,
        message: (
          <p>
            Oops! We could not cancel your subscription. Please contact{' '}
            <a href="mailto:support@valuestocks.io">support@valuestocks.io</a>{' '}
            to request a cancellation or follow the{' '}
            <a href="https://www.paypal.com/us/smarthelp/article/how-to-cancel-a-subscription-or-other-automatic-payments-faq2254">
              instructions to cancel via Paypal
            </a>
            .
          </p>
        ),
      })
    }
  }

  const renderDetails = () => {
    if (authContext.account) {
      const {
        plan,
        status,
        last_payment,
        next_payment,
        expired,
        days_before_expire,
      } = authContext.account

      if (loading) {
        return (
          <div className="account-section">
            <Loading />
          </div>
        )
      }

      const handleLogOut = async () => {
        await logout()
      }

      return (
        <div className="account-page">
          <div className="account-section">
            <Stack>
              <Card style={{ maxWidth: '350px' }} variant="outline">
                <CardContent>
                  <Stack spacing={2} alignItems="center">
                    <div>
                      Current plan: <b>{plan}</b>
                    </div>
                    <div>
                      Status: <b>{status}</b>
                    </div>
                    <div>
                      {plan === 'trial' ? 'Expires on:' : 'Renew on:'}{' '}
                      {plan === 'trial' && days_before_expire && (
                        <b>
                          {moment()
                            .add(days_before_expire, 'days')
                            .format('LL')}
                        </b>
                      )}
                      {plan === 'premium' && next_payment && (
                        <b>{moment(next_payment).format('LL')}</b>
                      )}
                    </div>
                    <div>
                      {status !== 'cancelled' && status !== 'inactive' && (
                        <Button
                          className="primary-button danger"
                          onClick={handleCancelSub}
                        >
                          Cancel Subscription
                        </Button>
                      )}
                    </div>
                    <div>
                      {(expired ||
                        days_before_expire === 0 ||
                        plan === 'trial') && <PlanSelectPayment />}
                      {error && error.showError && (
                        <Alert severity="error">
                          <AlertTitle>Error</AlertTitle>
                          {error.message}
                        </Alert>
                      )}
                    </div>
                  </Stack>
                </CardContent>
              </Card>

              <Button
                variant="outlined"
                style={{ maxWidth: '350px', marginTop: '1rem' }}
                size="large"
                fullWidth
                onClick={handleLogOut}
              >
                Log out
              </Button>
            </Stack>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <Fragment>
      <div className="account-page">{renderDetails()}</div>
    </Fragment>
  )
}

export default Account
