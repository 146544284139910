import { Fragment } from 'react'
import Explain from './Explain'

export const MarginOfSafetyTitle = () => {
  return (
    <div style={{ display: 'inline-flex' }}>
      Margin of safety&nbsp;
      <Explain
        title="Margin of safety"
        description={
          <Fragment>
            <span>
              The "margin of safety" in investment is a principle that involves
              purchasing securities when their market price is significantly
              lower than their intrinsic value, serving as a buffer against
              estimation errors or market downturns. This concept, popularized
              by Benjamin Graham and Warren Buffett, hinges on the difference
              between a security's intrinsic value, determined through
              fundamental analysis of factors like assets and earnings, and its
              market price.
            </span>
            <br />
            <br />
            <span>
              For example, if a stock's intrinsic value is calculated at $100
              but it's trading at $70, the margin of safety is $30, or 30%. This
              key principle of value investing emphasizes risk aversion,
              suggesting that in investments, it's often better to be
              approximately right than precisely wrong, and aims to provide a
              cushion against market volatility and analytical errors.
            </span>
          </Fragment>
        }
      />
    </div>
  )
}
