import React, { Fragment, useEffect, useState } from 'react'
import { ApiClient } from '../network/ApiClient'
import './Screener.css'
import { StockItem } from '../components/StockItem'
import { useAuthContext } from '../contexts/AuthContext'
import SubscriptionGuard from '../components/SubscriptionGuard'
import {
  Autocomplete,
  FormControl,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import IndexMenu from '../components/IndexMenu'
import sortBy from 'lodash/sortBy'
import StockItemHeader from '../components/StockItemHeader'
import { useParams, useSearchParams } from 'react-router-dom'
import throttle from 'lodash/throttle'

const DEFAULT_ITEMS_PER_PAGES = 10

const ScreenerPage = () => {
  const routeParams = useParams()
  const [searchParams, setSearchParams] = useSearchParams({
    page: 0,
    rows: DEFAULT_ITEMS_PER_PAGES,
    industry: '',
  })
  const [stocks, setStocks] = useState([])
  const [fullPageLoading, setFullPageLoading] = useState(true)
  const [semiPageLoading, setSemiPageLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const authContext = useAuthContext()
  const apiClient = new ApiClient()
  const [industries, setIndustryAverages] = useState([])
  const lgMediaQuery = useMediaQuery('(min-width:1024px)')
  const mdMediaQuery = useMediaQuery('(min-width:700px)')
  const [indexTotal, setIndexTotal] = useState(0)

  const page = Number(searchParams.get('page')) ?? 0
  const rowsPerPage =
    Number(searchParams.get('rows')) ?? DEFAULT_ITEMS_PER_PAGES
  const selectedIndustry = searchParams.get('industry') ?? ''
  const throttledSetSearchParams = throttle(
    (nextPage, rows) =>
      setSearchParams({ page: nextPage, rows, industry: selectedIndustry }),
    500
  )

  const fetchStocks = (
    indexName,
    page,
    showLoadingAnimation = true,
    params = {}
  ) => {
    setSemiPageLoading(true)

    apiClient.getIndustriesAverage(indexName).then(({ avgs }) => {
      setIndustryAverages(avgs)
    })

    apiClient
      .getScreenerResults(indexName, page * rowsPerPage, rowsPerPage, params)
      .then((results) => {
        setStocks(results.symbols)
        setIndexTotal(results.total)
        setSemiPageLoading(false)
        setFullPageLoading(false)
      })
      .catch((err) => {
        setStocks([])
        setIndexTotal(0)
        setFullPageLoading(false)
        setSemiPageLoading(false)
        if (err.status === 403) {
          authContext.setForbidden(true)
        }
      })
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })

    try {
      fetchStocks(routeParams.index, page, false, {
        industry: selectedIndustry,
      })
    } catch (err) {
      setFullPageLoading(false)
      setSemiPageLoading(false)
      if (err.status === 403) {
        authContext.setForbidden(true)
      }
    }
  }, [routeParams.index, rowsPerPage, page])

  const handleLoadMore = (event, nextPage) => {
    throttledSetSearchParams(nextPage, rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setSearchParams({
      page: 0,
      rows: event.target.value,
      industry: selectedIndustry,
    })
  }

  const renderList = (stocks) => {
    const searchCondition = (stock) =>
      stock.data.metadata.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      stock.ticker.toLowerCase().includes(searchTerm.toLowerCase())

    const stocksPages =
      searchCondition !== '' ? stocks.filter(searchCondition) : stocks

    const handleIndustryChange = (e) => {
      const textContent = e.target.textContent
      setSearchParams({
        page: 0,
        industry: textContent,
        rows: DEFAULT_ITEMS_PER_PAGES,
      })
      fetchStocks(routeParams.index, 0, true, { industry: textContent })
    }

    const industriesLabel = industries
      .filter((el) => el && el._id)
      .map((el) => el._id)

    if (!lgMediaQuery) {
      return (
        <Paper className="paper-div" variant="outline">
          <div>
            <IndexMenu currentIndex={routeParams.index} lg={lgMediaQuery} />
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Autocomplete
                size="small"
                onChange={handleIndustryChange}
                id="industry-autocomplete"
                options={sortBy(industriesLabel)}
                value={selectedIndustry}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Industry"
                    style={{ textAlign: 'left' }}
                  />
                )}
              ></Autocomplete>
            </FormControl>
          </div>

          {stocksPages &&
            stocksPages.length > 0 &&
            stocksPages.map((stock) => (
              <StockItem
                stock={stock}
                lg={lgMediaQuery}
                md={mdMediaQuery}
                key={stock.ticker}
              />
            ))}

          {!semiPageLoading && (
            <div style={{ margin: '2rem' }}>
              <button
                type="button"
                onClick={(e) => handleLoadMore(e, page + 1)}
                className="main-btn"
                title="Load More"
              >
                Load More
              </button>
            </div>
          )}
        </Paper>
      )
    }

    return (
      <div style={{ marginBottom: '2rem', minHeight: '800px' }}>
        <Paper className="paper-div" variant="outline">
          <TableContainer>
            <Toolbar
              style={{
                padding: '0 1rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <IndexMenu currentIndex={routeParams.index} lg={lgMediaQuery} />
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Autocomplete
                  onChange={handleIndustryChange}
                  size="small"
                  id="industry-autocomplete"
                  options={sortBy(industriesLabel)}
                  value={selectedIndustry}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Industry"
                      style={{ textAlign: 'left' }}
                    />
                  )}
                ></Autocomplete>
              </FormControl>
            </Toolbar>
            {indexTotal > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={indexTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleLoadMore}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            <Table stickyHeader>
              <TableHead>
                <StockItemHeader lg={lgMediaQuery} md={mdMediaQuery} />
              </TableHead>
              <TableBody>
                {!semiPageLoading && stocksPages.length === 0 && (
                  <div
                    style={{
                      margin: '0 auto',
                      fontSize: '1rem',
                      textAlign: 'center',
                    }}
                  >
                    <div>Not found</div>
                  </div>
                )}
                {semiPageLoading && <EmptyRows />}
                {!semiPageLoading &&
                  stocksPages.length > 0 &&
                  stocksPages
                    // .slice(startIndex, endIndex)
                    .map((stock, index) => (
                      <StockItem
                        stock={stock}
                        lg={lgMediaQuery}
                        md={mdMediaQuery}
                        key={`${index}-${stock.ticker}`}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {indexTotal > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={indexTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleLoadMore}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
    )
  }

  return (
    <SubscriptionGuard>
      <div className="screener-content">{renderList(stocks)}</div>
    </SubscriptionGuard>
  )
}

function EmptyRows() {
  const renderEmptyRow = () => (
    <TableRow>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
    </TableRow>
  )
  return (
    <Fragment>
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
      {renderEmptyRow()}
    </Fragment>
  )
}

export default ScreenerPage
