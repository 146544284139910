import { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ApiClient } from '../network/ApiClient'
import { logout } from '../services/auth'
import { removeCustomerId } from '../services/cache'
import { useAuthContext } from '../contexts/AuthContext'
import { useAppContext } from '../contexts/AppContext'
import useDebounce from './useDebounce'
import { InputAdornment, TextField, useMediaQuery } from '@mui/material'

const Search = ({ dark = false }) => {
  const authContext = useAuthContext()
  const searchContext = useAppContext()
  const navigate = useNavigate()
  const debouncedSearchTerm = useDebounce(searchContext.query, 150)
  const [isSearching, setSearching] = useState(false)
  const lgMediaQuery = useMediaQuery('(min-width:1024px)')

  const refInput = useRef()
  const refList = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        refInput.current &&
        !refInput.current.contains(event.target) &&
        refList.current &&
        !refList.current.contains(event.target)
      ) {
        searchContext.setQueryResults([])
        searchContext.setQuery('')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refInput])

  useEffect(() => {
    if (debouncedSearchTerm) {
      const apiClient = new ApiClient()
      apiClient.searchStock(debouncedSearchTerm).then(({ results }) => {
        searchContext.setQueryResults(results)
        setSearching(false)
      })
    } else {
      searchContext.setQueryResults([])
      setSearching(false)
    }
  }, [debouncedSearchTerm])

  const handleLogOut = async (e) => {
    e.preventDefault()
    await logout()
    removeCustomerId()
    navigate('/')
  }

  const handleQuerySearch = async (e) => {
    setSearching(true)
    const value = e.target.value
    searchContext.setQuery(value)
  }

  const renderFlag = (location) => {
    switch (location) {
      case 'US':
        return '🇺🇸'
      case 'CA':
        return '🇨🇦'
      case 'LN':
        return '🇬🇧'
      case 'MM':
        return '🇲🇽'
      case 'AU':
        return '🇦🇺'
      case 'NZ':
        return '🇳🇿'
      default:
        return '🇺🇸'
    }
  }

  const renderSearchBox = () => {
    if (!authContext.signedIn) {
      return null
    }

    return (
      <div className="vs-form">
        <form onSubmit={(e) => e.preventDefault()}>
          <TextField
            size="small"
            variant="outlined"
            id="ticker-input"
            type="text"
            ref={refInput}
            style={{ backgroundColor: 'white', borderRadius: '4px' }}
            value={searchContext.query}
            placeholder="Search by ticker or name"
            onChange={handleQuerySearch}
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="material-icons">search</span>
                </InputAdornment>
              ),
            }}
          />
        </form>
        {(searchContext.queryResults.length > 0 || isSearching) && (
          <ol className="search-result" ref={refList}>
            {isSearching && <li style={{ color: 'gray' }}>Searching ...</li>}
            {!isSearching &&
              searchContext.queryResults.map((stock) => {
                const [ticker, location] = stock.symbol.split(':')
                const url = `/analysis/${ticker}/${location}`
                return (
                  <Link to={url} key={stock.symbol}>
                    <li>
                      {renderFlag(location)} <b>{ticker}</b> - {stock.name}
                    </li>
                  </Link>
                )
              })}
          </ol>
        )}
      </div>
    )
  }

  return (
    <Fragment>
      <a href="https://valuestocks.io" alt="home" id="logo">
        <img
          className="header-logo"
          src={process.env.PUBLIC_URL + '/valuestocks-logo.png'}
          alt="logo"
        />
      </a>

      <div className={dark ? 'menu dark' : 'menu'}>
        <ul>
          {authContext.signedIn && lgMediaQuery && (
            <li>
              <Link to="/bookmarks">Bookmarks</Link>
            </li>
          )}

          {authContext.signedIn && lgMediaQuery && (
            <li>
              <Link to="/premium/SP500">Screener</Link>
            </li>
          )}

          {authContext.signedIn && lgMediaQuery && (
            <li>
              <Link to="/account">Account</Link>
            </li>
          )}

          {!authContext.signedIn && (
            <li>
              <Link className="primary-button" to="/signup">
                Sign up
              </Link>
            </li>
          )}

          {!authContext.signedIn && (
            <li>
              <Link to="/signin">Log in</Link>
            </li>
          )}

          {/* {authContext.signedIn && lgMediaQuery && (
            <li>
              <Link to="#" onClick={handleLogOut} alt="logout" title="Log out">
                <span class="material-icons">logout</span>&nbsp;Log out
              </Link>
            </li>
          )} */}
          {renderSearchBox()}
        </ul>
      </div>
    </Fragment>
  )
}

export default Search
