import moment from 'moment'
import { ApiClient } from '../network/ApiClient'
import { cacheData, getCache, STOCKS_DATA_KEY } from './cache'

export const getStockData = async (ticker, location, hasPremiumAccess) => {
  const apiClient = new ApiClient()
  // const symbol = `${ticker}:${location}`
  // const stockData = getCache(symbol, STOCKS_DATA_KEY)

  // if (!stockData || moment().isAfter(moment(stockData.ts), 'hour')) {
  const data = await apiClient.getStock(ticker, location, hasPremiumAccess)
  // cacheData(symbol, data, STOCKS_DATA_KEY)
  return data
  // }

  // console.log('Data loaded from cache')
  // return stockData
}
